import { useState } from "react";
import { useNavigate } from "react-router";
import { Spinner } from "react-bootstrap";
import {
	saveWorkExperience,
	updateWorkExperience,
} from "./../../ApiServices/Services/UserService";

const AddWorkExperienceForm = ({ shouldReload, previousData }) => {
	let navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [jobTitle, setJobTitle] = useState(previousData?.JobTitle ?? "");
	const [companyName, setCompanyName] = useState(
		previousData?.CompanyName ?? ""
	);
	const [startDate, setStartDate] = useState(previousData?.StartDate ?? "");
	const [endDate, setEndDate] = useState(previousData?.EndDate ?? "");
	const [responsibility, setResponsibility] = useState(
		previousData?.Responsibility ?? ""
	);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		const details = {
			JobTitle: jobTitle,
			StartDate: startDate,
			EndDate: endDate,
			Responsibility: responsibility,
			CompanyName: companyName,
		};

		if (previousData) {
			try {
				await updateWorkExperience({
					WEID: previousData.WEID,
					...details,
				});
				if (shouldReload) {
					window.location.reload(false);
				} else {
					navigate("/addeducation");
				}
			} catch (Error) {
				alert("Unable to update data, try again");
				setLoading(false);
			}
		} else {
			try {
				await saveWorkExperience(details);
				if (shouldReload) {
					window.location.reload(false);
				} else {
					navigate("/addeducation");
				}
			} catch (Error) {
				alert("Unable to submit data, try again");
				setLoading(false);
			}
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<div className="">
				<div className="mb-3">
					<label htmlFor="title" className="form-label fw-bold">
						Job title
					</label>
					<input
						className="form-control form-control"
						id="title"
						type="text"
						placeholder="Type your job title here..."
						value={jobTitle}
						onChange={(e) => setJobTitle(e.target.value)}
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="company" className="form-label fw-bold">
						Company name
					</label>
					<input
						className="form-control form-control"
						id="company"
						type="text"
						placeholder="Type the name of the company..."
						value={companyName}
						onChange={(e) => setCompanyName(e.target.value)}
						required
					/>
				</div>
				<div className="row">
					<div className="mb-3 col-6">
						<label htmlFor="startDate" className="form-label fw-bold">
							Start date
						</label>
						<input
							className="form-control form-control"
							id="startDate"
							type="date"
							value={startDate}
							onChange={(e) => setStartDate(e.target.value)}
							required
						/>
					</div>
					<div className="mb-3 col-6">
						<label htmlFor="endDate" className="form-label fw-bold">
							End date
						</label>
						<input
							className="form-control form-control"
							id="endDate"
							type="date"
							value={endDate}
							onChange={(e) => setEndDate(e.target.value)}
							required
						/>
					</div>
				</div>
				<div className="mb-3">
					<label htmlFor="responsibilities" className="fw-bold  mb-2">
						Your responsibilities
					</label>
					<textarea
						className="form-control"
						id="responsibilities"
						rows="5"
						placeholder="Type your responsibilites here..."
						value={responsibility}
						onChange={(e) => setResponsibility(e.target.value)}
					/>
				</div>
				<button className="btn btn-primary text-white px-5">
					{loading ? (
						<>
							<Spinner
								as="span"
								animation="grow"
								size="sm"
								role="status"
								aria-hidden="true"
							/>{" "}
							Saving
						</>
					) : (
						<>Save</>
					)}
				</button>
			</div>
		</form>
	);
};

export default AddWorkExperienceForm;
