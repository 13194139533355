import React from "react";
import "./Footer.scss";

const Footer = () => {
	return (
		<div className="footer ">
			<p className="text-center  fs-5">
				Supported by <span>Fieldbase Services Limited</span>
			</p>
		</div>
	);
};

export default Footer;
