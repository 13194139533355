import PageNotFoundImage from "../../images/404.png";

const NotFound = () => {
	return (
		<div className="d-flex justify-content-center mt-5">
			<div className="mt-5 mt-md-0">
				<img
					src={PageNotFoundImage}
					alt=""
					style={{ width: "95%", height: "95%" }}
					className="mt-lg-5"
				/>
			</div>
		</div>
	);
};

export default NotFound;
