import { Modal } from "react-bootstrap";

const ViewImage = (props) => {
	return (
		<Modal {...props} fullscreen="lg-down">
			<Modal.Header closeButton>
				<Modal.Title>See document in full</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<img
					src={`${process.env.REACT_APP_API_URL}/img/verificationdocument/${props.documentid}`}
					style={{ width: "100%", height: "100%" }}
					alt=""
				/>
			</Modal.Body>
		</Modal>
	);
};

export default ViewImage;
