import { Spinner } from "react-bootstrap";

const Loading = () => {
	return (
		<div
			className="d-flex justify-content-center align-items-center"
			style={{ minHeight: "100vh" }}
		>
			<Spinner animation="border" size="lg" variant="primary">
				<span className="visually-hidden">Loading...</span>
			</Spinner>
		</div>
	);
};

export default Loading;
