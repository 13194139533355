import { Modal, Spinner } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useState } from "react";
import { recommendUser } from "../../ApiServices/Services/RecommendationService";

const RecommendUser = (props) => {
	const [recommendationDetails, setRecommendationDetails] = useState("");
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			await recommendUser({
				UserID: props.user,
				Details: recommendationDetails,
			});
			window.location.reload(false);
		} catch (Error) {
			alert("Error recommending user");
			setLoading(false);
		}
	};

	return (
		<Modal
			{...props}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<div className="bg-white rounded p-sm-3 p-2">
				<div className="text-center mb-3" style={{ position: "relative" }}>
					<h3 className="fw-bold">
						Recommend{" "}
						<span className="text-primary ">
							{props.firstname + " " + props.lastname}
						</span>
					</h3>
					<button
						onClick={props.onHide}
						className="btn"
						style={{
							position: "absolute",
							top: "0",
							right: "0",
							fontSize: "20px",
							cursor: "pointer",
						}}
					>
						<AiOutlineClose />{" "}
					</button>
				</div>

				<form onSubmit={handleSubmit}>
					<div className="">
						<label htmlFor="recommendation" className="fw-bold  mb-2">
							Your recommendation
						</label>
						<textarea
							className="form-control mb-4"
							id="recommendation"
							rows="6"
							placeholder="Type your message here..."
							value={recommendationDetails}
							onChange={(e) => setRecommendationDetails(e.target.value)}
						/>
						<button className="btn btn-primary text-white px-5">
							{loading ? (
								<>
									<Spinner
										as="span"
										animation="grow"
										size="sm"
										role="status"
										aria-hidden="true"
									/>{" "}
									Submitting
								</>
							) : (
								<>Submit</>
							)}
						</button>
					</div>
				</form>
				<div className="d-flex justify-content-end mt-n3">
					<button className="btn btn-danger px-5 mt-n4" onClick={props.onHide}>
						Cancel
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default RecommendUser;
