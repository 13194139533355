import { Modal } from "react-bootstrap";
import ChangePasswordForm from "./../../Pages/Forms/ChangePasswordForm";

const ChangePassword = (props) => {
	return (
		<Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter">
			<Modal.Header closeButton>
				<h3 className="fw-bold">Change your password</h3>
			</Modal.Header>

			<Modal.Body>
				<ChangePasswordForm onHide={props.onHide} />
			</Modal.Body>
		</Modal>
	);
};

export default ChangePassword;
