import { Link } from "react-router-dom";
import { useState } from "react";
import { BsBookmarkPlus } from "react-icons/bs";
import { RiErrorWarningFill, RiEdit2Line } from "react-icons/ri";
import { ImWarning } from "react-icons/im";
import RecommendUser from "../../Components/Modals/RecommendUser";
import ReportUser from "../../Components/Modals/ReportUser";
import UserReportVersion from "../../Components/Modals/UserReportVersion";
import EmployeeReportVersion from "../../Components/Modals/EmployeeReportVersion";
import EditJobTitle from "../../Components/Modals/EditJobTitle";
import EditTelephoneNumber from "./../../Components/Modals/EditTelephoneNumber";
import auth from "../../ApiServices/Services/AuthService";
import ProfileImageUpdate from "./ProfileImage";
import UserAvatar from "../../images/useravatar.png";
import ChangePassword from "../../Components/Modals/ChangePassword";
import { Badge } from "react-bootstrap";

const ProfilePageHead = ({ data: user, userOwnsProfile, usersCase }) => {
	const viewersRole = auth.getCurrentUserRole();
	const viewersCompany = auth.getCurrentUserCompany();
	// these are the states for the modals
	const [recommendUser, setRecommendUser] = useState(false);
	const [reportUser, setReportUser] = useState(false);
	const [userReportVersion, setUserReportVersion] = useState(false);
	const [employeeReportVersion, setEmployeeReportVersion] = useState(false);
	const [editJobTitle, setEditJobTitle] = useState(false);
	const [editTelephoneNumber, setEditTelephoneNumber] = useState(false);
	const [editProfilePictureModal, setEditProfilePictureModal] = useState(false);
	const [changePasswordModal, setChangePasswordModal] = useState(false);

	const WhatProfileOwnerSee = () => {
		return (
			<>
				<div className="right">
					<div className="right-image">
						<img
							src={
								user.UserImageID
									? `${process.env.REACT_APP_API_URL}/imgw/userimage/${user.UserImageID}/100`
									: UserAvatar
							}
							alt=""
							style={{
								width: "155px",
								height: "155px",
							}}
						/>
						<button
							className="btn btn-primary text-white"
							onClick={() => setEditProfilePictureModal(true)}
						>
							<RiEdit2Line />
						</button>
					</div>
					<div className="ms-3 mt-n2">
						<span className="fw-bold fs-4 d-block text-capitalize">
							{user.Firstname + " " + user.Lastname + " "}
							{user.RoleID === "3" && (
								<Badge pill bg="info">
									Committee member
								</Badge>
							)}
						</span>
						<span className="text-capitalize">
							{user.JobTitle} at{" "}
							<Link
								to={`/company/${user.CompanyID}`}
								className="text-decoration-none"
							>
								{" "}
								{user.CompanyName}
							</Link>{" "}
							<button className="btn " onClick={() => setEditJobTitle(true)}>
								<RiEdit2Line className="action-icons text-primary fs-5 mt-n2" />
							</button>
						</span>
						<span className="d-block">
							Email:
							<span className="fw-bold">{" " + user.Email}</span>
						</span>
						<span className="d-block">
							Telephone:
							<span className="fw-bold">
								{user.Phone ? " " + user.Phone : " N/A"}
							</span>
							<button
								className="btn "
								onClick={() => setEditTelephoneNumber(true)}
							>
								<RiEdit2Line className="action-icons text-primary fs-5 mt-n2" />
							</button>
						</span>
						<button
							className="btn btn-primary text-white"
							onClick={() => setChangePasswordModal(true)}
						>
							Change password
						</button>
					</div>
					{usersCase && (
						<button
							className="reported-button btn btn-danger fw-bold px-4 mt-3"
							onClick={() => setUserReportVersion(true)}
						>
							<RiErrorWarningFill className="fs-5" />{" "}
							<span className="fw-bold">View Report</span>
						</button>
					)}
				</div>
				{usersCase && (
					<div className="reported-symbol">
						<button
							className="rounded-circle danger d-flex flex-column px-4 py-3"
							onClick={() => setUserReportVersion(true)}
						>
							<ImWarning
								style={{ width: "55px", height: "55px" }}
								className="text-danger"
							/>
							<span className="fw-bold text-danger">Reported</span>
						</button>
					</div>
				)}
			</>
		);
	};

	const WhatOthersSee = () => {
		return (
			<>
				<div className="right">
					<div className="right-image pb-3">
						<img
							src={
								user.UserImageID
									? `${process.env.REACT_APP_API_URL}/imgw/userimage/${user.UserImageID}/100`
									: UserAvatar
							}
							alt=""
							style={{ width: "150px", height: "150px" }}
						/>
					</div>
					<div className="right-details ms-3 mt-n3">
						<span className="fw-bold fs-4 d-block">
							{user.Firstname + " " + user.Lastname}
						</span>
						<span className="">
							{user.JobTitle} at{" "}
							<Link
								to={`/company/${user.CompanyID}`}
								className="text-decoration-none"
							>
								{" "}
								{user.CompanyName}
							</Link>{" "}
						</span>
						<span className="d-block">
							Email:{" "}
							<span className="fw-bold">
								{viewersRole !== "1" ? user.Email : "N/A"}
							</span>
						</span>
						<span className="d-block">
							Telephone:
							<span className="fw-bold">
								{user.Phone ? (
									viewersRole !== "1" ? (
										<span className="fw-bold">{" " + user.Phone}</span>
									) : (
										" N/A"
									)
								) : (
									<span className="fw-bold"> N/A </span>
								)}
							</span>
						</span>
						{user.RoleID === "3" && (
							<span className="fw-bold d-block">(Commitee member)</span>
						)}
						<div className="mt-2">
							<button
								className="btn  btn-primary text-white px-4"
								onClick={() => setRecommendUser(true)}
							>
								{" "}
								<BsBookmarkPlus /> <span className="fw-bold">Recommend</span>
							</button>

							{viewersRole === "2" &&
								viewersCompany === user.CompanyID &&
								!usersCase && (
									<button
										className="btn btn-danger  fw-bold ms-2 px-4 "
										onClick={() => setReportUser(true)}
									>
										<RiErrorWarningFill className="fs-5" />{" "}
										<span className="fw-bold">Report</span>
									</button>
								)}

							{usersCase
								? usersCase?.ReportJudgeID !== "" && (
										<button
											className="reported-button btn btn-danger  fw-bold ms-2 px-4 "
											onClick={() => setEmployeeReportVersion(true)}
										>
											<RiErrorWarningFill className="fs-5" />{" "}
											<span className="fw-bold">View Report</span>
										</button>
								  )
								: null}
						</div>
					</div>
				</div>
				{usersCase
					? usersCase?.ReportJudgeID !== "" && (
							<div className="reported-symbol">
								<button
									className="rounded-circle danger d-flex flex-column px-4 py-3"
									onClick={() => setEmployeeReportVersion(true)}
								>
									<ImWarning
										style={{ width: "55px", height: "55px" }}
										className="text-danger"
									/>
									<span className="fw-bold text-danger">Reported</span>
								</button>
							</div>
					  )
					: null}
			</>
		);
	};

	return (
		<>
			<>
				<ProfileImageUpdate
					show={editProfilePictureModal}
					onHide={() => setEditProfilePictureModal(false)}
					imageid={user.UserImageID}
				/>

				<EditJobTitle
					show={editJobTitle}
					jobtitle={user.JobTitle}
					onHide={() => setEditJobTitle(false)}
				/>

				<EditTelephoneNumber
					show={editTelephoneNumber}
					phonenumber={user.Phone}
					onHide={() => setEditTelephoneNumber(false)}
				/>

				<ChangePassword
					show={changePasswordModal}
					onHide={() => setChangePasswordModal(false)}
				/>

				<RecommendUser
					show={recommendUser}
					onHide={() => setRecommendUser(false)}
					firstname={user.Firstname}
					lastname={user.Lastname}
					user={user.UserID}
				/>

				<ReportUser
					show={reportUser}
					onHide={() => setReportUser(false)}
					user={user.UserID}
					firstname={user.Firstname}
					lastname={user.Lastname}
				/>

				{usersCase && (
					<>
						<UserReportVersion
							show={userReportVersion}
							onHide={() => setUserReportVersion(false)}
							report={usersCase}
						/>
						<EmployeeReportVersion
							show={employeeReportVersion}
							onHide={() => setEmployeeReportVersion(false)}
							report={usersCase}
						/>
					</>
				)}
			</>

			<>
				<div className="profile-head p-2 p-sm-4 p-sm-4 ">
					{userOwnsProfile ? <WhatProfileOwnerSee /> : <WhatOthersSee />}
				</div>
			</>
		</>
	);
};

export default ProfilePageHead;
