import { Navigate } from "react-router-dom";
import auth from "./../ApiServices/Services/AuthService";

const ProtectedRoute = ({ children }) => {
	const userLoggedIn = auth.getCurrentUserID();
	if (!userLoggedIn) {
		return <Navigate to="/login" replace />;
	}
	return children;
};

export default ProtectedRoute;
