import React, { useState, useEffect } from "react";
import { getClosedCase } from "../../ApiServices/Services/CaseService";
import auth from "./../../ApiServices/Services/AuthService";
import NotFound from "./../NotFound/NotFound";
import Loading from "./../../Components/Loading";
import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { IoMdArrowRoundBack } from "react-icons/io";

const ViewClosedCase = () => {
	const viewersRole = auth.getCurrentUserRole();

	const PageContent = () => {
		let { closedCaseId } = useParams();
		let navigate = useNavigate();
		const [loading, setLoading] = useState(true);
		const [caseDetails, setCaseDetails] = useState([]);

		useEffect(() => {
			const getCaseDetails = async () => {
				const { data } = await getClosedCase(closedCaseId);
				if (data) {
					setCaseDetails(data.obj[0]);
					setLoading(false);
				}
			};
			getCaseDetails();
		}, [closedCaseId]);

		return (
			<>
				{loading ? (
					<Loading />
				) : (
					<div className="p-3">
						<div className="bg-light p-4 border">
							<h5 className="fw-bold">Reported User details</h5>
							<Table striped responsive="md">
								<tbody>
									<tr>
										<td>
											<strong>Name :</strong>
										</td>
										<td className="text-end">{`${caseDetails.ext.Report.Firstname} ${caseDetails.ext.Report.Lastname}`}</td>
									</tr>
									<tr>
										<td>
											<strong>Job title :</strong>
										</td>
										<td className="text-end">
											{caseDetails.ext.Report.JobTitle}
										</td>
									</tr>
									<tr>
										<td>
											<strong>Company :</strong>
										</td>
										<td className="text-end">
											{caseDetails.ext.Report.CompanyName}
										</td>
									</tr>
								</tbody>
							</Table>
							<h5 className="fw-bold mt-4">Case Details</h5>
							<Table striped responsive="md">
								<tbody>
									<tr>
										<td>
											<strong>Problem :</strong>
										</td>
										<td className="text-end">
											{caseDetails.ext.Report.Details}
										</td>
									</tr>
									<tr>
										<td>
											<strong>Company proposed sentence :</strong>
										</td>
										<td className="text-end">
											{caseDetails.ext.Report.ActionDetails}
										</td>
									</tr>
									<tr>
										<td>
											<strong>Date created :</strong>
										</td>
										<td className="text-end">
											{caseDetails.ext.Report.DateCreated}
										</td>
									</tr>
								</tbody>
							</Table>
							<h5 className="fw-bold mt-4">How committee members voted</h5>
							<Table striped responsive="md">
								<tbody>
									<tr>
										<td>
											<strong>Forgive employee :</strong>
										</td>
										<td className="text-end">
											{
												caseDetails.ext.ReportAdvice.filter(
													(no) => no.ProposedActionID === "1"
												).length
											}{" "}
											votes
										</td>
									</tr>
									<tr>
										<td>
											<strong>Industrial warning :</strong>
										</td>
										<td className="text-end">
											{
												caseDetails.ext.ReportAdvice.filter(
													(no) => no.ProposedActionID === "2"
												).length
											}{" "}
											votes
										</td>
									</tr>
									<tr>
										<td>
											<strong>
												Barred for future employment in the industry :
											</strong>
										</td>
										<td className="text-end">
											{
												caseDetails.ext.ReportAdvice.filter(
													(no) => no.ProposedActionID === "3"
												).length
											}{" "}
											votes
										</td>
									</tr>
									<tr>
										<td>
											<strong>Suspened for future employment :</strong>
										</td>
										<td className="text-end">
											{
												caseDetails.ext.ReportAdvice.filter(
													(no) => no.ProposedActionID === "4"
												).length
											}{" "}
											votes
										</td>
									</tr>
								</tbody>
							</Table>
							<h5 className="fw-bold mt-4">Committee final decision</h5>
							<Table striped responsive="md">
								<tbody>
									<tr>
										<td>
											<strong>Committee decision :</strong>
										</td>
										<td className="text-end">
											{caseDetails.ext.RecommendedAction.ActionDetails}
										</td>
									</tr>
									<tr>
										<td>
											<strong>Case closed by :</strong>
										</td>
										<td className="text-end">{`${caseDetails.ext.Judge.Firstname} ${caseDetails.ext.Judge.Lastname}`}</td>
									</tr>
									<tr>
										<td>
											<strong>Date case closed :</strong>
										</td>
										<td className="text-end">
											{caseDetails.ext.Report.DateClosed}
										</td>
									</tr>
								</tbody>
							</Table>
							<button
								className="btn btn-info text-white mt-2"
								onClick={() => navigate(-1)}
							>
								<IoMdArrowRoundBack />
								<span className="ms-1">Go back</span>
							</button>
						</div>
					</div>
				)}
			</>
		);
	};

	return viewersRole === "3" || viewersRole === "4" ? (
		<PageContent />
	) : (
		<NotFound />
	);
};

export default ViewClosedCase;
