import "./Forms.scss";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import UserAvatar from "../../images/useravatar.png";
import { saveDisplayPicture } from "./../../ApiServices/Services/UserService";

const AddProfilePicture = () => {
	let navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [image, setImage] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		let formData = new FormData();
		formData.append("ImageData", image);

		try {
			await saveDisplayPicture(formData);
			setLoading(false);
			navigate("/confirmaccount/2");
		} catch (Error) {
			alert("Unable to submit data, try again");
			setLoading(false);
		}
	};

	return (
		<div className="form-page d-flex justify-content-center align-items-center">
			<div className="add-profile form-container bg-white py-4 px-sm-3 px-1 ">
				<div className="progresss d-flex justify-content-center">
					<span className="active"></span>
					<span className="active"></span>
					<span className="active"></span>
					<span className="active"></span>
				</div>
				<h3 className="text-center fw-bold mb-3">Your Profile Picture</h3>

				<form onSubmit={handleSubmit}>
					<div className="upload d-flex align-items-center">
						<div className="upload-picture">
							<img
								src={image ? URL.createObjectURL(image) : UserAvatar}
								alt=""
							/>
							<label
								htmlFor="selectImage"
								className="plus btn btn-primary text-white fs-3 fw-bold"
							>
								+
							</label>
							<input
								type="file"
								id="selectImage"
								onChange={(e) => setImage(e.target.files[0])}
								hidden
							/>
						</div>
						<div className="upload-side">
							<p className="fw-bold ">Upload a picture of your yourself</p>
							<p>
								You can upload images up to 256x256. Your profile picture shows
								up in your public profile and your team notifications.
							</p>

							<button className="btn btn-primary text-white px-4" type="submit">
								{loading ? (
									<>
										<Spinner
											as="span"
											animation="grow"
											size="sm"
											role="status"
											aria-hidden="true"
										/>{" "}
										Saving
									</>
								) : (
									<>Save</>
								)}
							</button>
						</div>
					</div>
				</form>
				<div className="d-flex justify-content-end mt-n3">
					<Link
						className="btn btn-outline-secondary px-5 mt-n4"
						to="/confirmaccount/2"
					>
						Skip
					</Link>
				</div>
			</div>
		</div>
	);
};

export default AddProfilePicture;
