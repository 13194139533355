import { RiEdit2Line } from "react-icons/ri";
import { useState } from "react";
import EditAbout from "../../Components/Modals/EditAbout";

const ProfileAbout = ({ data: About, userOwnsProfile }) => {
	const [editAbout, setEditAbout] = useState(false);

	return (
		<>
			<EditAbout
				show={editAbout}
				about={About}
				onHide={() => setEditAbout(false)}
			/>

			<div className="profile-about p-2 px-sm-4 ">
				<div className="d-flex justify-content-between align-items-start ">
					<p className="fw-bold fs-4">About </p>
					{userOwnsProfile && (
						<button className="btn" onClick={() => setEditAbout(true)}>
							<RiEdit2Line className="action-icons text-primary fs-4" />
						</button>
					)}
				</div>
				<p className="">{About === "" ? "Bio not added" : About}</p>
			</div>
		</>
	);
};

export default ProfileAbout;
