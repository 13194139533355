import "./User.scss";
import UserAvatar from "../../images/useravatar.png";
import { Link } from "react-router-dom";

const User = ({
	firstname,
	lastname,
	jobTitle,
	company,
	companyID,
	userImageId,
	userId,
}) => {
	return (
		<div className="user bg-white container-fluid d-flex align-items-center justify-content-between py-sm-4 py-2 px-sm-4  px-2 rounded hoverable">
			<div className="left d-flex">
				<div className="picture d-flex flex-column justify-content-center -aling-items-center">
					<img
						src={
							userImageId
								? `${process.env.REACT_APP_API_URL}/imgw/userimage/${userImageId}/100`
								: UserAvatar
						}
						alt=""
					/>
				</div>
				<div className="name">
					<p className="fw-bold text-capitalize">{`${
						firstname + " " + lastname
					}`}</p>
					<p className="mt-n2 text-capitalize">
						{jobTitle} at{" "}
						<span className="text-primary ">
							<Link
								to={`/company/${companyID}`}
								className="text-decoration-none"
							>
								{company}
							</Link>
						</span>
					</p>
					<Link
						to={`/profile/${userId}`}
						className="btn btn-outline-primary vprofile"
					>
						View Profile
					</Link>
				</div>
			</div>
			<div className="right">
				<Link to={`/profile/${userId}`} className="btn btn-outline-primary">
					View Profile
				</Link>
			</div>
		</div>
	);
};

export default User;
