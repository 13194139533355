import "./Employees.scss";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { getCompanyEmployees } from "../../ApiServices/Services/EmployeeService";
import auth from "../../ApiServices/Services/AuthService";
import EmployeesTable from "../../Components/EmployeesTable/EmployeesTable";
import Loading from "../../Components/Loading";
import NotFound from "../NotFound/NotFound";

const PagePaginationItem = ({ itemsPagePage, data }) => {
	const [currentPage, setCurrentPage] = useState(0);
	const offset = currentPage * itemsPagePage;
	const pageCount = Math.ceil(data.length / itemsPagePage);
	const currentPageData = Array.from(data).slice(
		offset,
		offset + itemsPagePage
	);

	const handlePageClick = ({ selected: selectedPage }) => {
		setCurrentPage(selectedPage);
	};

	return (
		<>
			<EmployeesTable data={currentPageData} />

			<div className="d-flex justify-content-center mt-3">
				<ReactPaginate
					nextLabel="next >>"
					onPageChange={handlePageClick}
					pageRangeDisplayed={3}
					marginPagesDisplayed={2}
					pageCount={pageCount}
					previousLabel="<< previous"
					pageClassName="page-item"
					pageLinkClassName="page-link"
					previousClassName="page-item"
					previousLinkClassName="page-link"
					nextClassName="page-item"
					nextLinkClassName="page-link"
					breakLabel="..."
					breakClassName="page-item"
					breakLinkClassName="page-link"
					containerClassName="pagination"
					activeClassName="active"
					renderOnZeroPageCount={null}
				/>
			</div>
		</>
	);
};

const Employees = () => {
	let { companyID } = useParams();
	const viewersRole = auth.getCurrentUserRole();

	const PageContent = ({ company }) => {
		const [loading, setLoading] = useState(true);
		const [employees, setEmployees] = useState("");
		const [searchString, setSearchString] = useState("");

		useEffect(() => {
			const getAllEmployees = async () => {
				const { data } = await getCompanyEmployees(companyID);
				setEmployees(data.obj);
				setLoading(false);
			};
			getAllEmployees();
		}, [company]);

		return (
			<>
				{loading ? (
					<Loading />
				) : (
					<div className=" container-lg employees pt-4 pb-2 ">
						{employees.length > 0 ? (
							<>
								<div className="heading">
									<p className="fw-bold">Employees</p>

									<div className="heading-right">
										<div className="input-group mb-3">
											<input
												type="text"
												className="form-control"
												placeholder="Search employee"
												value={searchString}
												onChange={(e) => setSearchString(e.target.value)}
											/>
										</div>
									</div>
								</div>

								<div className="employees-body table-responsive-lg">
									<PagePaginationItem
										itemsPagePage={10}
										data={employees.filter((emp) =>
											`${emp.Firstname} ${emp.Lastname}`
												.toLowerCase()
												.includes(searchString.trim().toLowerCase())
										)}
									/>
								</div>
							</>
						) : (
							<Alert variant="danger">
								<Alert.Heading>No user</Alert.Heading>
								<p>This company currently have no user registered</p>
							</Alert>
						)}
					</div>
				)}
			</>
		);
	};

	return viewersRole !== "1" ? (
		<PageContent company={companyID} />
	) : (
		<NotFound />
	);
};

export default Employees;
