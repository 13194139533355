import "./Cases.scss";
import { useState, useEffect } from "react";
import { ListGroup, Tab, Alert } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import {
	getClosedCases,
	getOpenCasesForCM,
	getVotedCases,
} from "../../ApiServices/Services/CaseService";
import auth from "../../ApiServices/Services/AuthService";
import UserCase from "../../Components/UserCases/UserCase";
import Loading from "../../Components/Loading";
import NotFound from "./../NotFound/NotFound";
import ClosedCases from "./ClosedCases";

const PagePaginationItem = ({
	itemsPagePage,
	data,
	caseType,
	userSelectedAction,
	votedCaseId,
}) => {
	const [currentPage, setCurrentPage] = useState(0);
	const offset = currentPage * itemsPagePage;
	const pageCount = Math.ceil(data.length / itemsPagePage);
	const currentPageData = Array.from(data).slice(
		offset,
		offset + itemsPagePage
	);

	const handlePageClick = ({ selected: selectedPage }) => {
		setCurrentPage(selectedPage);
	};

	return (
		<>
			<div className="cases-list">
				{currentPageData.map((cas, index) => (
					<UserCase
						key={index}
						reportId={cas.ReportID}
						userID={cas.UserID}
						firstname={cas.Firstname}
						lastname={cas.Lastname}
						JobTitle={cas.JobTitle}
						CompanyName={cas.CompanyName}
						CompanyID={cas.CompanyID}
						problem={cas.Details}
						sentenceProposed={cas.ActionDetails}
						userStory={
							cas.ext?.length > 0 ? cas.ext.UserStories[0].Details : null
						}
						caseType={caseType}
						userSelectedAction={
							userSelectedAction ? userSelectedAction[0] : null
						}
						votedCaseId={votedCaseId ? votedCaseId[0] : null}
					/>
				))}
			</div>

			<div className="d-flex justify-content-center mt-3">
				<ReactPaginate
					nextLabel="next >>"
					onPageChange={handlePageClick}
					pageRangeDisplayed={3}
					marginPagesDisplayed={2}
					pageCount={pageCount}
					previousLabel="<< previous"
					pageClassName="page-item"
					pageLinkClassName="page-link"
					previousClassName="page-item"
					previousLinkClassName="page-link"
					nextClassName="page-item"
					nextLinkClassName="page-link"
					breakLabel="..."
					breakClassName="page-item"
					breakLinkClassName="page-link"
					containerClassName="pagination"
					activeClassName="active"
					renderOnZeroPageCount={null}
				/>
			</div>
		</>
	);
};

const Cases = () => {
	const viewersRole = auth.getCurrentUserRole();

	const PageContent = () => {
		const [loading, setLoading] = useState(true);
		const [cases, setCases] = useState([]);
		const [votedCases, setVotedCases] = useState([]);
		const [closedCases, setClosedCases] = useState([]);

		useEffect(() => {
			const getAllCases = async () => {
				const { data } = await getOpenCasesForCM();
				if (data) {
					setCases(data.obj);
					setLoading(false);
				}
			};

			getAllCases();
		}, []);

		useEffect(() => {
			const getUserVotedCases = async () => {
				const { data } = await getVotedCases();
				setVotedCases(data.obj);
				setLoading(false);
			};
			getUserVotedCases();
		}, []);

		useEffect(() => {
			const getUserClosedCases = async () => {
				const { data } = await getClosedCases();
				setClosedCases(data.obj);
				setLoading(false);
			};
			getUserClosedCases();
		}, []);

		return (
			<>
				{loading ? (
					<Loading />
				) : (
					<Tab.Container defaultActiveKey="#openCases">
						<div className="container-fluid cases py-4 px-4">
							<div className="heading mb-3">
								<p className="fw-bold">Cases to be reviewed</p>
								<ListGroup horizontal>
									<ListGroup.Item action href="#openCases">
										Open
									</ListGroup.Item>
									{viewersRole === "3" && (
										<ListGroup.Item type="button" action href="#votedCases">
											Voted
										</ListGroup.Item>
									)}
									<ListGroup.Item action href="#closedCases">
										Closed
									</ListGroup.Item>
								</ListGroup>
							</div>

							<Tab.Content>
								<Tab.Pane eventKey="#openCases">
									<div>
										{cases.length !== 0 ? (
											<PagePaginationItem
												itemsPagePage={12}
												data={cases}
												caseType="open"
											/>
										) : (
											<Alert variant="danger">
												<Alert.Heading>No open cases</Alert.Heading>
												<p>You currently do not have any case to vote to .</p>
											</Alert>
										)}
									</div>
								</Tab.Pane>

								{viewersRole === "3" && (
									<Tab.Pane eventKey="#votedCases">
										{votedCases.length !== 0 ? (
											<PagePaginationItem
												itemsPagePage={12}
												caseType="voted"
												data={votedCases.map((vw) => vw.ext.VwReport)}
												userSelectedAction={votedCases.map(
													(vw) => vw.ProposedActionID
												)}
												votedCaseId={votedCases.map((vw) => vw.ReportAdviceID)}
											/>
										) : (
											<Alert variant="danger">
												<Alert.Heading>Zero cases voted</Alert.Heading>
												<p>You have not voted in any case.</p>
											</Alert>
										)}
									</Tab.Pane>
								)}

								<Tab.Pane eventKey="#closedCases">
									{closedCases.length !== 0 ? (
										<ClosedCases data={closedCases} itemsPagePage="10" />
									) : (
										<Alert variant="danger">
											<Alert.Heading>Zero closed case</Alert.Heading>
											<p>There are currently no closed case.</p>
										</Alert>
									)}
								</Tab.Pane>
							</Tab.Content>
						</div>
					</Tab.Container>
				)}
			</>
		);
	};

	return viewersRole === "3" || viewersRole === "4" ? (
		<PageContent />
	) : (
		<NotFound />
	);
};

export default Cases;
