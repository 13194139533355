import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import routes from "./Routes";
import Footer from "./Components/Footer/Footer";
import NavbarComponent from "./Components/Navbar/NavbarComponent";
import ProtectedRoute from "./Components/ProtectedRoute";

function App() {
	return (
		<Router>
			<Routes>
				{routes.map((route, index) => {
					const Element = () => {
						if (route.layout) {
							return (
								<>
									<div className="app">
										<div className="app-background"></div>
										<div className="app-body">
											<div>
												<NavbarComponent />
												<route.element />
											</div>
											<Footer />
										</div>
									</div>
								</>
							);
						} else {
							return <route.element />;
						}
					};

					return route.protected ? (
						<Route
							key={index}
							path={route.path}
							element={<ProtectedRoute>{<Element />}</ProtectedRoute>}
						/>
					) : (
						<Route key={index} path={route.path} element={<Element />} />
					);
				})}
			</Routes>
		</Router>
	);
}

export default App;
