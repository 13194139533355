import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import {
	saveDisplayPicture,
	updateDisplayPicture,
} from "../../ApiServices/Services/UserService";
import UserAvatar from "../../images/useravatar.png";

const ProfileImageUpdate = (props) => {
	const [loading, setLoading] = useState(false);
	const [newProfilePicture, setNewProfilePicture] = useState("");

	const updateProfilePicture = async (e) => {
		e.preventDefault();
		setLoading(true);

		let imageData = new FormData();
		imageData.append("ImageData", newProfilePicture);
		if (props.imageid === "") {
			try {
				await saveDisplayPicture(imageData);
				window.location.reload(false);
			} catch (Error) {
				setLoading(false);
				alert("Error saving profile picture");
			}
		} else {
			imageData.append("UserImageID", props.imageid);
			try {
				await updateDisplayPicture(imageData);
				window.location.reload(false);
			} catch (Error) {
				setLoading(false);
				alert("Error updating profile picture");
			}
		}
	};

	return (
		<Modal {...props} size="md">
			<Modal.Body>
				<div>
					<img
						src={
							newProfilePicture
								? URL.createObjectURL(newProfilePicture)
								: props.imageid
								? `${process.env.REACT_APP_API_URL}/img/userimage/${props.imageid}`
								: UserAvatar
						}
						style={{ width: "100%", height: "100%" }}
						alt=""
					/>
					<form onSubmit={updateProfilePicture}>
						<div className="form-group d-flex flex-column my-3  ">
							<label htmlFor="image">Select new profile picture</label>
							<input
								type="file"
								className="form-control-file"
								id="image"
								onChange={(e) => setNewProfilePicture(e.target.files[0])}
								required
							/>
						</div>

						<button className="btn btn-primary text-white px-sm-5">
							{loading ? (
								<>
									<Spinner
										as="span"
										animation="grow"
										size="sm"
										role="status"
										aria-hidden="true"
									/>{" "}
									Saving
								</>
							) : (
								<>Save</>
							)}
						</button>
					</form>

					<div className="d-flex justify-content-end mt-n3">
						<button
							className="btn btn-danger px-sm-5 mt-n4"
							onClick={props.onHide}
						>
							Cancel
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ProfileImageUpdate;
