import "./Forms.scss";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { saveIdentityDocument } from "../../ApiServices/Services/UserService";
import { useNavigate } from "react-router";

const ConfirmIdentity = () => {
	let navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [documentType, setDocumentType] = useState("");
	const [documentNumber, setDocumentNumber] = useState("");
	const [documentImage, setDocumentImage] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		const formData = new FormData();
		formData.append("DTID", documentType);
		formData.append("DocumentNumber", documentNumber);
		formData.append("ImageData", documentImage);

		try {
			await saveIdentityDocument(formData);
			setLoading(false);
			navigate("/confirmaccount/1");
		} catch (Error) {
			setLoading(false);
			alert("error submitting data, try again");
		}
	};

	return (
		<div className="form-page d-flex justify-content-center align-items-center">
			<div className="form-container bg-white pt-5 pb-4 px-sm-5 px-3">
				<div className="progresss d-flex justify-content-center">
					<span className="active"></span>
					<span className="active"></span>
					<span className="active"></span>
					<span></span>
				</div>
				<h3 className="text-center mb-4 fw-bold">Confirm your identity</h3>

				<form className="confirm-identity" onSubmit={handleSubmit}>
					<p className="fw-bold">IDENTIFICATION DOCUMENT</p>
					<div className="document-type">
						<div className="form-check form-check-inline">
							<input
								className="form-check-input"
								type="radio"
								name="documentType"
								id="inlineRadio1"
								value="1"
								onChange={(e) => setDocumentType(e.target.value)}
							/>
							<label className="form-check-label" htmlFor="inlineRadio1">
								Passport
							</label>
						</div>
						<div className="form-check form-check-inline">
							<input
								className="form-check-input"
								type="radio"
								name="documentType"
								id="inlineRadio2"
								value="2"
								onChange={(e) => setDocumentType(e.target.value)}
							/>
							<label className="form-check-label" htmlFor="inlineRadio2">
								COC Number
							</label>
						</div>
						<div className="form-check form-check-inline">
							<input
								className="form-check-input"
								type="radio"
								name="documentType"
								id="inlineRadio3"
								value="3"
								onChange={(e) => setDocumentType(e.target.value)}
							/>
							<label className="form-check-label" htmlFor="inlineRadio3">
								Seafarer ID
							</label>
						</div>
					</div>

					<div className="passport mt-3">
						<label htmlFor="documentNumber" className="form-label fw-bold">
							DOCUMENT NUMBER
						</label>
						<input
							className="form-control form-control-sm"
							id="documentNumber"
							type="text"
							value={documentNumber}
							onChange={(e) => setDocumentNumber(e.target.value)}
						/>
					</div>

					<div className="document-image mt-3">
						<label
							htmlFor="identificationDocument"
							className="form-label fw-bold"
						>
							IDENTIFICATION DOCUMENT
						</label>
						<input
							className="form-control form-control"
							id="identificationDocument"
							type="file"
							onChange={(e) => setDocumentImage(e.target.files[0])}
						/>
					</div>

					<div className="d-grid gap-2 mt-4">
						<button
							className="btn btn-primary text-white py-2 fw-bold"
							type="submit"
						>
							{loading ? (
								<>
									<Spinner
										as="span"
										animation="grow"
										size="sm"
										role="status"
										aria-hidden="true"
									/>{" "}
									Saving
								</>
							) : (
								<>Submit</>
							)}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ConfirmIdentity;
