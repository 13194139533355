import http, { axiosJsonToPost } from "../Utils/HttpService";

const apiEndPoint = "fetch/user";
const apitEndPointVerifyUser = "fetch/vwverification";

async function loginUser(credentials) {
	const { data } = await http.post(apiEndPoint, axiosJsonToPost(credentials));
	return data;
}

function isUserVerified(userId) {
	return http.post(
		apitEndPointVerifyUser,
		axiosJsonToPost({ UserID: userId, VerificationStatusID: "1" })
	);
}

function logoutUser() {
	localStorage.removeItem("user");
}

function getCurrentUserID() {
	try {
		const user = JSON.parse(localStorage.getItem("user"));
		return user.UserID;
	} catch (ex) {
		return null;
	}
}

function getCurrentUserRole() {
	try {
		const user = JSON.parse(localStorage.getItem("user"));
		return user.RoleID;
	} catch (ex) {
		return null;
	}
}

function getCurrentUserCompany() {
	try {
		const user = JSON.parse(localStorage.getItem("user"));
		return user.CompanyID;
	} catch (ex) {
		return null;
	}
}

const auth = {
	loginUser,
	isUserVerified,
	logoutUser,
	getCurrentUserID,
	getCurrentUserRole,
	getCurrentUserCompany,
};

export default auth;
