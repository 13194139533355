import "./CompanyProfile.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { RiEdit2Line } from "react-icons/ri";
import { getCompany } from "./../../ApiServices/Services/CompanyService";
import Loading from "../../Components/Loading";
import EditCompanyProfile from "../../Components/Modals/EditCompanyProfile";
import auth from "../../ApiServices/Services/AuthService";
import EditCompanyLogo from "../../Components/Modals/EditCompanyLogo";
import CompanyAvatar from "../../images/companyavatar.png";

const CompanyProfile = () => {
	let { id } = useParams();
	const userRole = auth.getCurrentUserRole();
	const userCompany = auth.getCurrentUserCompany();
	const [loading, setLoading] = useState(true);
	const [company, setCompany] = useState("");
	const [editProfileModal, setEditProfileModal] = useState(false);
	const [editCompanyLogoModal, setEditCompanyLogoModal] = useState(false);

	useEffect(() => {
		const fetchCompany = async () => {
			const { data } = await getCompany(id);
			setCompany(data.obj[0]);
			setLoading(false);
		};
		fetchCompany();
	}, [id]);

	return (
		<>
			<EditCompanyProfile
				show={editProfileModal}
				onHide={() => setEditProfileModal(false)}
				company={company}
			/>

			<EditCompanyLogo
				show={editCompanyLogoModal}
				onHide={() => setEditCompanyLogoModal(false)}
				imageid={company.CLID}
			/>

			{loading ? (
				<Loading />
			) : (
				<div className="company container bg-white p-4  my-3">
					<div className="left me-md-4">
						<div className="left-image mb-3">
							<img
								src={
									company.CLID
										? `${process.env.REACT_APP_API_URL}/imgw/companylogo/${company.CLID}/300`
										: CompanyAvatar
								}
								alt=""
								style={{ width: "190px", height: "190px" }}
							/>
							{userRole === "2" &&
								userCompany === company.CompanyID.toString() && (
									<button
										className="btn btn-primary text-white"
										onClick={() => setEditCompanyLogoModal(true)}
									>
										<RiEdit2Line />
									</button>
								)}
						</div>

						{userRole === "2" && userCompany === company.CompanyID.toString() && (
							<button
								className="btn btn-outline-primary mb-3"
								onClick={() => setEditProfileModal(true)}
							>
								Edit Company Profile
							</button>
						)}
					</div>

					<div className="right">
						<div className="right-head pe-lg-5">
							<h2 className="fw-bold">{company.CompanyName}</h2>
							<p className="text-muted">{company.Moto}</p>
							<p>{company.About}</p>
						</div>
						<div className="right-bottom">
							<p>
								<span className="fw-bold">Industry category :</span>{" "}
								<span>{company.CategoryID}</span>
							</p>
							<p>
								<span className="fw-bold">Company size :</span>{" "}
								<span>{company.CompanySize} employees</span>
							</p>
							<p>
								<span className="fw-bold">Year founded :</span>{" "}
								<span>{company.YearFounded}</span>
							</p>
							<p>
								<span className="fw-bold">Location :</span>{" "}
								<span>{company.Address}</span>
							</p>
							<p>
								<span className="fw-bold">Website : </span>
								<span>
									<a
										href={encodeURI(`https://${company.Website}`)}
										className="text-decoration-none"
									>
										{company.Website}
									</a>
								</span>
							</p>
							<p>
								<span className="fw-bold">Contact :</span>{" "}
								<span>{company.Contact}</span>
							</p>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default CompanyProfile;
