import http, { axiosJsonToPost } from "../Utils/HttpService";

const apiEndPointFetch = "/fetch/vwverification";

export function getAllEmployees(companyID) {
	return http.get(apiEndPointFetch);
}

export async function getCompanyEmployees(companyID) {
	return http.post(apiEndPointFetch, axiosJsonToPost({ CompanyID: companyID }));
}
