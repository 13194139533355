import { useState } from "react";
import { Spinner } from "react-bootstrap";
import auth from "../../ApiServices/Services/AuthService";
import { useNavigate } from "react-router";
import {
	updateUser,
	verifyUserPassword,
} from "../../ApiServices/Services/UserService";

const ChangePasswordForm = ({ onHide, forReset }) => {
	let navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		if (newPassword !== confirmPassword) {
			setErrorMessage("Passwords do not match");
			setLoading(false);
		} else {
			setErrorMessage("");

			if (forReset) {
				try {
					await updateUser({ Password: newPassword });
					auth.logoutUser();
					navigate("/login");
				} catch (error) {
					alert("Error changing password");
					setLoading(false);
				}
			} else {
				try {
					const { data } = await verifyUserPassword(currentPassword);
					if (data.obj.length < 1) {
						setErrorMessage("Current password is incorrect");
						setLoading(false);
					} else {
						try {
							await updateUser({ Password: newPassword });
							auth.logoutUser();
							navigate("/login");
						} catch (error) {
							alert("Error changing password");
							setLoading(false);
						}
					}
				} catch (error) {
					alert("Error changing password");
					setLoading(false);
				}
			}
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<div className="">
				<h2 className="fw-bold text-center">Reset your password</h2>
				{errorMessage && (
					<p className="text-danger fw-bold fs-4 mb-n1">* {errorMessage}</p>
				)}
				{!forReset && (
					<div className="mb-3">
						<label htmlFor="forCurrentPassword" className="fw-bold">
							Current Password
						</label>
						<input
							type="password"
							className="form-control"
							id="forCurrentPassword"
							value={currentPassword}
							onChange={(e) => setCurrentPassword(e.target.value)}
							required
						/>
					</div>
				)}

				<div className="mb-3">
					<label htmlFor="forNewPassword" className="fw-bold">
						New Password
					</label>
					<input
						type="password"
						className="form-control"
						id="forNewPassword"
						value={newPassword}
						onChange={(e) => setNewPassword(e.target.value)}
						required
						minLength={5}
					/>
				</div>

				<div className="mb-3">
					<label htmlFor="forConfirmPassword" className="fw-bold">
						Confirm Password
					</label>
					<input
						type="password"
						className="form-control"
						id="forConfirmPassword"
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
						required
					/>
				</div>

				<div className="d-flex justify-content-between align-items-center">
					<button className="btn btn-primary text-white px-sm-3">
						{loading ? (
							<>
								<Spinner
									as="span"
									animation="grow"
									size="sm"
									role="status"
									aria-hidden="true"
								/>{" "}
								Changing password...
							</>
						) : (
							<>Change password</>
						)}
					</button>
					{!forReset && (
						<button
							className="btn btn-danger px-sm-3"
							type="button"
							onClick={onHide}
						>
							Cancel
						</button>
					)}
				</div>
			</div>
		</form>
	);
};

export default ChangePasswordForm;
