import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const http = {
	get: axios.get,
	put: axios.put,
	post: axios.post,
	delete: axios.delete,
};

export default http;

// PHP doesn't support JSON as a data format for populating $_POST.
// This function is used to convert the data from json to what php understands
export function axiosJsonToPost(data) {
	let p = new URLSearchParams();
	Object.keys(data).forEach(function (key) {
		p.append(key, this[key]);
	}, data);

	return p;
}
