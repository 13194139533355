import "./Home.scss";
import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import SearchPeople from "../../Components/SearchPeople/SearchPeople";
import UsersList from "../../Components/UsersList/UsersList";
import { getUsers } from "../../ApiServices/Services/UserService";
import Loading from "../../Components/Loading";

const PagePaginationItem = ({ itemsPagePage, data }) => {
	const [currentPage, setCurrentPage] = useState(0);
	const offset = currentPage * itemsPagePage;
	const pageCount = Math.ceil(data.length / itemsPagePage);
	const currentPageData = Array.from(data).slice(
		offset,
		offset + itemsPagePage
	);

	const handlePageClick = ({ selected: selectedPage }) => {
		setCurrentPage(selectedPage);
	};

	return (
		<>
			<UsersList data={currentPageData} />

			<div className="d-flex justify-content-center mt-3">
				<ReactPaginate
					nextLabel="next >>"
					onPageChange={handlePageClick}
					pageRangeDisplayed={3}
					marginPagesDisplayed={2}
					pageCount={pageCount}
					previousLabel="<< previous"
					pageClassName="page-item"
					pageLinkClassName="page-link"
					previousClassName="page-item"
					previousLinkClassName="page-link"
					nextClassName="page-item"
					nextLinkClassName="page-link"
					breakLabel="..."
					breakClassName="page-item"
					breakLinkClassName="page-link"
					containerClassName="pagination"
					activeClassName="active"
					renderOnZeroPageCount={null}
				/>
			</div>
		</>
	);
};

const Home = () => {
	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState("");
	const [searchedName, setSearchedName] = useState("");

	useEffect(() => {
		const getAllUsers = async () => {
			const { data } = await getUsers();
			setUsers(data.obj);
			setLoading(false);
		};
		getAllUsers();
	}, []);

	const getName = (name) => {
		setLoading(false);
		setSearchedName(name);
	};

	return (
		<>
			{loading ? (
				<Loading />
			) : (
				<>
					<div className="home  py-4 px-sm-5 p-2 d-flex justify-content-center align-items-center">
						<SearchPeople func={getName} setLoading={setLoading} />
					</div>

					{searchedName.length > 1 && users && (
						<PagePaginationItem
							itemsPagePage={4}
							data={users.filter((user) =>
								`${user.Firstname} ${user.Lastname}`
									.toLowerCase()
									.includes(searchedName.trim().toLowerCase())
							)}
						/>
					)}
				</>
			)}
		</>
	);
};

export default Home;
