import "./Forms.scss";
import { Form, Spinner, Toast, ToastContainer } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { useState } from "react";
import auth from "../../ApiServices/Services/AuthService";

const Login = () => {
	let navigate = useNavigate();

	const LoginContent = () => {
		const [loading, setLoading] = useState(false);
		const [email, setEmail] = useState("");
		const [password, setPassword] = useState("");
		const [loginError, setLoginError] = useState(false);
		const [showToast, setShowToast] = useState(false);

		const handleSubmit = async (e) => {
			e.preventDefault();
			setLoading(true);

			const credentials = {
				Email: email,
				Password: password,
			};

			try {
				//first check if user record exitst else invalid details
				const data = await auth.loginUser(credentials);
				if (data.obj.length === 1) {
					const user = {
						UserID: data.obj[0].UserID,
						RoleID: data.obj[0].RoleID,
						CompanyID: data.obj[0].CompanyID,
					};

					//login if user is a committee member or fieldbase admin or has been approved by company
					const { data: d } = await auth.isUserVerified(user.UserID);
					if (user.RoleID === "3" || user.RoleID === "4" || d.obj.length > 0) {
						localStorage.setItem("user", JSON.stringify(user));
						navigate("/");
					} else {
						navigate("/confirmaccount/3");
						auth.logoutUser();
					}
				} else {
					setLoginError(true);
					setLoading(false);
				}
			} catch (Error) {
				setShowToast(true);
				setLoading(false);
			}
		};

		return (
			<>
				<ToastContainer position="top-end">
					<Toast
						className="d-inline-block m-1"
						bg="danger"
						onClose={() => setShowToast(false)}
						show={showToast}
						delay={7000}
						autohide
						animation={true}
					>
						<Toast.Header>
							<strong className="me-auto">Login Failure</strong>
						</Toast.Header>
						<Toast.Body className="text-white">
							Unable to connect, kindly trying again.
						</Toast.Body>
					</Toast>
				</ToastContainer>

				<div className="form-page d-flex justify-content-center align-items-center">
					<div className="form-container bg-white pt-5 pb-3 px-sm-5 px-3 ">
						<h3 className="text-center fw-bold mb-2">Welcome back!</h3>
						{loginError && (
							<p className="text text-danger fw-bold fs-4 mb-n1">
								Invalid login details
							</p>
						)}

						<Form onSubmit={handleSubmit}>
							<Form.Group className="mb-3" controlId="formBasicEmail">
								<Form.Label className="fw-bold fs-6">EMAIL</Form.Label>
								<Form.Control
									type="email"
									placeholder="Your email"
									autoComplete="off"
									onChange={(e) => setEmail(e.target.value)}
									value={email}
									required
								/>
							</Form.Group>

							<Form.Group className="mb-3 pt-1" controlId="formBasicPassword">
								<Form.Label className="fw-bold fs-6">PASSWORD</Form.Label>
								<Form.Control
									type="password"
									placeholder="Your Password"
									onChange={(e) => setPassword(e.target.value)}
									value={password}
									required
								/>
								<Link to="/forgotpassword" className="text-decoration-none">
									<p className="text-small text-primary text-end">
										Forgot Password?
									</p>
								</Link>
							</Form.Group>

							<div className="d-grid gap-2">
								<button className="btn btn-primary text-white py-2 fw-bold">
									{loading ? (
										<>
											<Spinner
												as="span"
												animation="grow"
												size="sm"
												role="status"
												aria-hidden="true"
											/>{" "}
											Signing in...
										</>
									) : (
										<>Sign In </>
									)}
								</button>
							</div>

							<Link to="/signup" className="text-decoration-none">
								<p className="mt-3 text-center text-primary ">
									You don’t have an account?
								</p>
							</Link>
						</Form>
					</div>
				</div>
			</>
		);
	};

	return (
		<>
			<LoginContent />
		</>
	);
};

export default Login;
