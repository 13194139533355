import http, { axiosJsonToPost } from "../Utils/HttpService";
import auth from "./AuthService";

const user = auth.getCurrentUserID();
const apiEndPointFetchAllReports = "fetch/vwreport";
const apiEndPointFetchOpenCases = "fetch/pendingreport";
const apiEndPointFetchVotedCases = "fetch/vwreportadvice";
const apiEndPointCreateReport = "save/report";
const apiEndPointSaveReportComment = "save/reportcomment";
const apiEndPointSaveVote = "save/reportadvice";
const apiEndPointUpdateVote = "update/reportadvice";
const apiEndPointCloseCase = "save/reportjudge";
const apiEndPointFetchClosedCases = "fetch/reportjudge";

export function getAllCases() {
	return http.get(apiEndPointFetchAllReports);
}

export function getOpenCasesForCM() {
	return http.post(
		apiEndPointFetchOpenCases,
		axiosJsonToPost({ UserID: user })
	);
}

export function getVotedCases() {
	return http.post(apiEndPointFetchVotedCases, { UserID: user });
}

export function getClosedCase(closedCaseId) {
	return http.post(`${apiEndPointFetchClosedCases}/${closedCaseId}`);
}

export function getClosedCases() {
	return http.post(apiEndPointFetchClosedCases);
}

export function getCase(userId) {
	return http.post(
		apiEndPointFetchAllReports,
		axiosJsonToPost({ UserID: userId })
	);
}

export function reportUser(details) {
	return http.post(
		apiEndPointCreateReport,
		axiosJsonToPost({ ReportedBy: auth.getCurrentUserID(), ...details })
	);
}

export function reportedUserComment(details) {
	return http.post(apiEndPointSaveReportComment, axiosJsonToPost(details));
}

export function voteCase(details) {
	return http.post(
		apiEndPointSaveVote,
		axiosJsonToPost({ UserID: user, ...details })
	);
}

export function updateVote(details) {
	return http.post(
		apiEndPointUpdateVote,
		axiosJsonToPost({ UserID: user, ...details })
	);
}

export function closeCase(details) {
	return http.post(
		apiEndPointCloseCase,
		axiosJsonToPost({ JudgeID: user, ...details })
	);
}
