import { Link, useParams } from "react-router-dom";

const ConfirmAccount = () => {
	let { code } = useParams();

	return (
		<div className="form-page d-flex justify-content-center align-items-center">
			<div className="form-container bg-white p-4 ">
				<h3 className="text-center fw-bold">Waiting for confirmation</h3>
				{code === "1" && (
					<>
						<p className="my-3">
							We are only waiting for your employer to confirm your identity. We
							will send you an email and let you know when it’s done. <br />
							Why don't you complete your profile in the meantime
						</p>

						<div className="d-grid gap-2 mt-4">
							<Link
								to="/addbio"
								className="btn btn-success text-white py-2 fw-bold"
							>
								Continue to fill the details
							</Link>
						</div>
					</>
				)}

				{code === "2" && (
					<>
						<p className="mt-3">
							All your details has been saved successfully, we are still waiting
							for your employer to confirm your identity.
							<br />
							We will send you an email and let you know when it's done.
						</p>
						<div className="d-grid gap-2 mt-4">
							<Link
								to="/login"
								className="btn btn-primary text-white py-2 fw-bold"
							>
								Go to login
							</Link>
						</div>
					</>
				)}

				{code === "3" && (
					<>
						<p className="mt-3">
							You employer has not verified you as an employee of the company.
							<br /> We will send you an email once you have been accepted.
						</p>
						<div className="d-grid gap-2 mt-4">
							<Link
								to="/login"
								className="btn btn-primary text-white py-2 fw-bold"
							>
								Go back to login
							</Link>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default ConfirmAccount;
