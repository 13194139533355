import { Modal } from "react-bootstrap";

const EmployeeReportVersion = (props) => {
	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<h3 className="fw-bold text-danger">
					{props.report.ActionDetails}
					<span className=" d-block d-lg-inline"></span>
				</h3>
			</Modal.Header>

			<Modal.Body>
				<div className="bg-white rounded">
					<div>
						<p>
							<span className="fw-bold">What was the problem</span>
							<span className="text-secondary d-block">
								{props.report.Details}
							</span>
						</p>
					</div>

					<div className="">
						<div>
							<p>
								<span className="fw-bold">{`${props.report.Firstname}'s version`}</span>
								<span className="text-secondary d-block">
									{props.report.ext.UserStories.length < 1 ? (
										<>This user has not given their side of the story</>
									) : (
										props.report.ext.UserStories[0].Details
									)}
								</span>
							</p>
						</div>
					</div>

					<div className="d-flex justify-content-end">
						<button className="btn btn-danger px-5" onClick={props.onHide}>
							Cancel
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default EmployeeReportVersion;
