import { useState } from "react";
import { useNavigate } from "react-router";
import { Spinner, ToastContainer, Toast } from "react-bootstrap";
import {
	fetchUserIdByEmail,
	sendEmailToken,
} from "../../ApiServices/Services/UserService";

const ForgotPassword = () => {
	let navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [error, setError] = useState("");
	const [showToast, setShowToast] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			const { data } = await fetchUserIdByEmail(email);
			if (data.obj.length === 0) {
				setError("This email address is not found in SEAFARERS REGISTER");
			} else {
				setError("");
				const userId = data.obj[0].UserID;
				localStorage.setItem("user", JSON.stringify({ UserID: userId }));
				await sendEmailToken();
				navigate("/confirmemail/chg");
			}
			setLoading(false);
		} catch (error) {
			setShowToast(true);
			setLoading(false);
		}
	};

	return (
		<>
			<ToastContainer position="top-end">
				<Toast
					className="d-inline-block m-1"
					bg="danger"
					onClose={() => setShowToast(false)}
					show={showToast}
					delay={7000}
					autohide
					animation={true}
				>
					<Toast.Header>
						<strong className="me-auto">Connection error</strong>
					</Toast.Header>
					<Toast.Body className="text-white">
						Unable to connect, kindly trying again.
					</Toast.Body>
				</Toast>
			</ToastContainer>

			<div className="form-page d-flex justify-content-center align-items-center">
				<div className="form-container bg-white pt-5 pb-3 px-sm-5 px-3 ">
					<h3 className="text-center fw-bold">Forgotten password ?</h3>

					<p className="text-muted text-center mb-3">
						Please insert your email address,
						<br /> token will be sent to your email to allow you reset your
						password
					</p>

					{error && <p className="text-danger fw-bold mb-n3">* {error}</p>}

					<form onSubmit={handleSubmit}>
						<div className="mt-3">
							<label htmlFor="email" className="form-label fw-bold">
								EMAIL ADDRESS
							</label>
							<input
								className="form-control form-control-sm"
								id="email"
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								required
							/>
						</div>

						<div className="d-flex justify-content-between align-items-center mt-4">
							<button
								className="btn btn-primary text-white py-2  px-sm-5 fw-bold"
								type="submit"
							>
								{loading ? (
									<>
										<Spinner
											as="span"
											animation="grow"
											size="sm"
											role="status"
											aria-hidden="true"
										/>{" "}
										Sending...
									</>
								) : (
									<>Send Token</>
								)}
							</button>
							<button
								className="btn btn-secondary text-white py-2 px-sm-5  fw-bold"
								type="button"
								onClick={() => navigate("/")}
							>
								Go to login
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default ForgotPassword;
