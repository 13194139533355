import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { ImCheckmark, ImCross } from "react-icons/im";
import { makeCompanyAdmin } from "../../ApiServices/Services/UserService";

const MakeCompanyAdmin = (props) => {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");

	const closeModal = () => {
		setStatus("");
		setLoading(false);
		props.onHide();
	};

	const submitForm = async () => {
		setLoading(true);

		if (status === "1") {
			try {
				const { data } = await makeCompanyAdmin();
				if (data.obj.RoleID === "2") {
					window.location.reload(false);
				} else {
					alert("Error saving status");
				}
			} catch (error) {
				alert("Error submitting data");
			}
			setLoading(false);
		} else {
			props.onHide();
			setLoading(false);
		}
	};

	return (
		<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
			<Modal.Header>
				<h3 className="fw-bold">Make {props.firstname} an admin</h3>
			</Modal.Header>

			<Modal.Body>
				<p>
					Are you sure you want to make {props.firstname} an admin of this
					company?
				</p>

				<div className="d-flex justify-content-around">
					<div className="d-flex flex-column align-items-center">
						<button
							className="btn btn-success rounded-circle p-3"
							onClick={() => setStatus("1")}
						>
							<ImCheckmark className="fs-1" />
						</button>
						<span className="text-success">Yes</span>
					</div>

					<div className="d-flex flex-column align-items-center">
						<button
							className="btn btn-danger rounded-circle p-3"
							onClick={() => setStatus("0")}
						>
							<ImCross className="fs-1 fw-bold" />
						</button>
						<span className="text-danger">No</span>
					</div>
				</div>

				<div className="d-flex justify-content-between align-items-center mt-4">
					{status !== "" ? (
						<button
							className="btn btn-primary text-white px-md-5 px-3"
							onClick={submitForm}
						>
							{loading ? (
								<>
									<Spinner
										as="span"
										animation="grow"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
									Approving...
								</>
							) : (
								<>Submit</>
							)}
						</button>
					) : (
						<button
							className="btn btn-primary text-white px-md-5 px-3"
							disabled
							onClick={submitForm}
						>
							Submit
						</button>
					)}

					<button className="btn btn-danger px-md-5 px-3" onClick={closeModal}>
						Cancel
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default MakeCompanyAdmin;
