import { Carousel } from "react-bootstrap";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import UserAvatar from "../../images/useravatar.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import RecommendUser from "./../../Components/Modals/RecommendUser";

const ProfileRecommendation = ({ data, userOwnsProfile, user }) => {
	const [recommendUserModal, setRecommendUserModal] = useState(false);
	return (
		<>
			<RecommendUser
				show={recommendUserModal}
				onHide={() => setRecommendUserModal(false)}
				firstname={user.Firstname}
				lastname={user.Lastname}
				user={user.UserID}
			/>

			<div className="profile-recommendations p-2 px-sm-4 ">
				<div className="d-flex justify-content-between align-items-start recommend-head">
					<p className="fw-bold fs-4">Recommendations </p>
					{!userOwnsProfile ? (
						<button
							className="btn text-primary"
							onClick={() => setRecommendUserModal(true)}
						>
							Recommend <span>{user.Firstname}</span>
						</button>
					) : null}
				</div>

				<div className="recommend-body  my-2">
					{data.length > 0 ? (
						<Carousel
							variant="dark"
							controls={false}
							interval="4000"
							className="testimonials"
						>
							{data.map((recommender, index) => (
								<Carousel.Item className="pb-3" key={index}>
									<div className="testimonials__card p-2 mb-3">
										<p className="lh-lg">
											<FaQuoteLeft className="text-primary" />
											<span className="mx-1">{recommender.Details}</span>
											<FaQuoteRight className="text-primary" />
										</p>
										<div className="name d-flex justify-content-center align-items-end">
											<img
												src={
													recommender.UserImageID
														? `${process.env.REACT_APP_API_URL}/imgw/userimage/${recommender.UserImageID}/100`
														: UserAvatar
												}
												alt=""
												className="img-fluid me-1"
											/>
											<Link
												to={`/profile/${recommender.RecommendedBy}`}
												className="text-decoration-none fs-4"
											>
												{recommender.Firstname + " " + recommender.Lastname}
											</Link>
										</div>
									</div>
								</Carousel.Item>
							))}
						</Carousel>
					) : (
						<div>This user has not been recommended by any one</div>
					)}
				</div>
			</div>
		</>
	);
};

export default ProfileRecommendation;
