import http, { axiosJsonToPost } from "../Utils/HttpService";
import auth from "./AuthService";

const apiEndPointFetchCompany = "fetch/vwcompany";
const apiEndPointCreateCompany = "save/company";
const apiEndPointUpdateCompany = "update/company";
const apiEndPointFetchSector = "fetch/sector";
const apiEndPointSaveCompanyLogo = "save/companylogo";
const apiEndPointUpdateCompanyLogo = "update/companylogo";

export function getCompanies() {
	return http.get(apiEndPointFetchCompany);
}

export function getCompany(companyId) {
	return http.get(`${apiEndPointFetchCompany}/${companyId}`);
}

export function createCompany(details) {
	return http.post(apiEndPointCreateCompany, axiosJsonToPost(details));
}

export function updateCompany(details) {
	return http.post(apiEndPointUpdateCompany, axiosJsonToPost(details));
}

export function fetchSectors() {
	return http.get(apiEndPointFetchSector);
}

export function saveCompanyLogo(imageData) {
	imageData.append("CompanyID", auth.getCurrentUserCompany());
	return http.post(apiEndPointSaveCompanyLogo, imageData);
}

export async function updateCompanyLogo(imageData) {
	return http.post(apiEndPointUpdateCompanyLogo, imageData);
}
