const CompanySignup = () => {
	return (
		<div className="form-page d-flex justify-content-center align-items-center">
			<div className="form-container bg-white pt-5 pb-4 px-sm-5 px-3">
				<h3 className="text-center mb-4 fw-bold">Register company</h3>

				<form className="confirm-identity">
					<div className="mb-3">
						<label htmlFor="companyName" className="form-label fw-bold">
							Company Name
						</label>
						<input
							className="form-control form-control"
							id="companyName"
							type="text"
						/>
					</div>

					<div className="mb-3">
						<label htmlFor="companyNumber" className="form-label fw-bold">
							Company Number
						</label>
						<input
							className="form-control form-control"
							id="companyNumber"
							type="text"
						/>
					</div>

					<div className="mb-3">
						<label className="form-label fw-bold" htmlFor="industry">
							Industry
						</label>
						<select className="form-control custom-select" id="industry">
							<option selected>Choose...</option>
							<option value="1">Telecommunications</option>
							<option value="2">Maritime</option>
							<option value="3">Security</option>
						</select>
					</div>

					<div className="d-grid gap-2 ">
						<button
							className="btn btn-primary text-white py-2 fw-bold"
							type="submit"
							disabled
						>
							Create account
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default CompanySignup;
