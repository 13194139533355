import { Link } from "react-router-dom";
import AddEducationForm from "./AddEducationForm";

const AddEducation = () => {
	return (
		<div className="form-page d-flex justify-content-center align-items-center py-4">
			<div className="add-profile form-container bg-white pt-3 pb-3 px-sm-4 px-3 ">
				<div className="progresss d-flex justify-content-center">
					<span className="active"></span>
					<span className="active"></span>
					<span className="active"></span>
					<span></span>
				</div>
				<h3 className="text-center fw-bold mb-3">Education</h3>

				<AddEducationForm />

				<div className="d-flex justify-content-end mt-n3">
					<Link
						className="btn btn-outline-secondary px-5 mt-n4"
						to="/uploadpicture"
					>
						Skip
					</Link>
				</div>
			</div>
		</div>
	);
};

export default AddEducation;
