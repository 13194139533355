import "./Notification.scss";
import { BsEmojiSmile } from "react-icons/bs";
import { Alert } from "react-bootstrap";
import { useEffect, useState } from "react";
import Loading from "../../Components/Loading";
import { fetchUserNotifications } from "./../../ApiServices/Services/NotificationService";

const Notification = () => {
	const [loading, setLoading] = useState(true);
	const [notifications, setNotifications] = useState(null);

	useEffect(() => {
		const getUserNotificaions = async () => {
			const { data } = await fetchUserNotifications();
			setNotifications(data.obj);
			setLoading(false);
		};
		getUserNotificaions();
	}, []);

	return (
		<>
			{loading ? (
				<Loading />
			) : (
				<div className="notification container my-3">
					<p className="fw-bold fs-2  text-center">Notifications</p>

					{notifications.length > 0 ? (
						<div className="bg-light rounded">
							{notifications.map((not, index) => (
								<div
									className="notification--list p-2 p-sm-3 d-flex"
									key={index}
								>
									<div className="right d-flex justify-content-center align-items-center pe-2 pe-sm-3">
										<div className="p-2 rounded-circle">
											<BsEmojiSmile />
										</div>
									</div>
									<div className="left">
										<span>
											<span className="fw-bold text-uppercase">
												{not.Subject.toUpperCase()}
											</span>
											<br />
											<span>{not.Content}</span> <br />
											<small className="text-muted text-small">
												{not.DateCreated}
											</small>
										</span>
									</div>
								</div>
							))}
						</div>
					) : (
						<Alert variant="danger">
							<Alert.Heading>No Notification</Alert.Heading>
							<p>You currently have not notification.</p>
						</Alert>
					)}
				</div>
			)}
		</>
	);
};

export default Notification;
