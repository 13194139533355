import { useState } from "react";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";

const ClosedCases = ({ itemsPagePage, data }) => {
	const navigate = useNavigate();
	const [currentPage, setCurrentPage] = useState(0);
	const offset = currentPage * itemsPagePage;
	const pageCount = Math.ceil(data.length / itemsPagePage);
	const currentPageData = Array.from(data).slice(
		offset,
		offset + itemsPagePage
	);

	const handlePageClick = ({ selected: selectedPage }) => {
		setCurrentPage(selectedPage);
	};

	return (
		<>
			<Table bordered responsive="xl" className="bg-white">
				<thead>
					<tr>
						<th>#</th>
						<th>Reported user</th>
						<th>Users' company</th>
						<th>Committee Decision</th>
						<th>Date Closed</th>
					</tr>
				</thead>
				<tbody>
					{currentPageData.map((cs, index) => (
						<tr key={index}>
							<td>{index + 1}</td>
							<td>
								<Link
									to={`/profile/${cs.ext.Report.UserID}`}
									className="text-decoration-none"
								>{`${cs.ext.Report.Firstname} ${cs.ext.Report.Lastname}`}</Link>
							</td>
							<td>
								<Link
									to={`/company/${cs.ext.Report.CompanyID}`}
									className="text-decoration-none"
								>
									{cs.ext.Report.CompanyName}
								</Link>
							</td>
							<td>{cs.ext.RecommendedAction.ActionDetails}</td>
							<td>{cs.DateCreated}</td>
							{/* <td>{new Date(cs.DateCreated.replace(/-/g, "/"))}</td> */}
							<td>
								<button
									className="btn btn-info text-white"
									onClick={() =>
										navigate(`/viewclosedcase/${cs.ReportJudgeID}`)
									}
								>
									View details
								</button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>

			<div className="d-flex justify-content-center mt-3">
				<ReactPaginate
					nextLabel="next >>"
					onPageChange={handlePageClick}
					pageRangeDisplayed={3}
					marginPagesDisplayed={2}
					pageCount={pageCount}
					previousLabel="<< previous"
					pageClassName="page-item"
					pageLinkClassName="page-link"
					previousClassName="page-item"
					previousLinkClassName="page-link"
					nextClassName="page-item"
					nextLinkClassName="page-link"
					breakLabel="..."
					breakClassName="page-item"
					breakLinkClassName="page-link"
					containerClassName="pagination"
					activeClassName="active"
					renderOnZeroPageCount={null}
				/>
			</div>
		</>
	);
};

export default ClosedCases;
