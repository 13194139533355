import { useNavigate } from "react-router";
import { Modal } from "react-bootstrap";
import auth from "./../../ApiServices/Services/AuthService";

const Logout = (props) => {
	let navigate = useNavigate();

	const handleLogout = () => {
		auth.logoutUser();
		navigate("/login");
	};
	return (
		<Modal {...props} size="md" backdrop="static" centered>
			<Modal.Body>
				<div className="p-sm-3 px-sm-5 ">
					<p className="fw-bold fs-4 text-center mb-4">
						Are you sure you want to logout?
					</p>
					<div className="d-flex justify-content-between align-items-center">
						<button
							className="btn btn-danger text-white px-5"
							onClick={handleLogout}
						>
							Yes
						</button>
						<button
							className="btn btn-primary text-white px-5"
							onClick={() => props.onHide(false)}
						>
							No
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default Logout;
