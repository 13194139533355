import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { reportUser } from "../../ApiServices/Services/CaseService";

const ReportUser = (props) => {
	const [loading, setLoading] = useState(false);
	const [reportMessage, setReportMessage] = useState("");
	const [disciplinaryID, setDiscplinaryID] = useState(0);
	const selectedButtonClassName =
		"d-flex align-items-center justify-content-center col-6 col-xl-3 p-2 border-2 rounded text-center mb-3 hoverable reportActionButtons";

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		const details = {
			UserID: props.user,
			Details: reportMessage,
			ProposedActionID: disciplinaryID,
		};

		try {
			await reportUser(details);
			window.location.reload(false);
		} catch (error) {
			alert("Error reporting user");
			setLoading(false);
		}
	};

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<h3 className="fw-bold">
					Report{" "}
					<span className="text-danger">
						{props.firstname + " " + props.lastname}
					</span>
				</h3>
			</Modal.Header>

			<Modal.Body>
				<div className="bg-white rounded">
					<form onSubmit={handleSubmit}>
						<div className="">
							<label htmlFor="forProblem" className="fw-bold  mb-2">
								What was the problem
							</label>
							<textarea
								className=" form-control mb-4"
								id="forProblem"
								rows="6"
								placeholder="Type your message here...
                            "
								value={reportMessage}
								onChange={(e) => setReportMessage(e.target.value)}
								required
							/>
						</div>

						<p className="fw-bold">Proposed disciplinary action</p>
						<div className="row d-flex align-items-center justify-content-between mb-4 px-2">
							<label
								className={
									disciplinaryID === "1"
										? selectedButtonClassName + " reportActionButtons-selected"
										: selectedButtonClassName
								}
								htmlFor="forForgive"
							>
								Forgive person
								<input
									type="radio"
									id="forForgive"
									name="discipline"
									value="1"
									onClick={(e) => setDiscplinaryID(e.target.value)}
									hidden
								/>
							</label>

							<label
								className={
									disciplinaryID === "2"
										? selectedButtonClassName + " reportActionButtons-selected"
										: selectedButtonClassName
								}
								htmlFor="forIndustrialWarning"
							>
								Industrial warning
								<input
									type="radio"
									id="forIndustrialWarning"
									name="discipline"
									value="2"
									onClick={(e) => setDiscplinaryID(e.target.value)}
									hidden
								/>
							</label>

							<label
								className={
									disciplinaryID === "3"
										? selectedButtonClassName + " reportActionButtons-selected"
										: selectedButtonClassName
								}
								htmlFor="forBarred"
							>
								Barred for future employment in the industry
								<input
									type="radio"
									id="forBarred"
									name="discipline"
									value="3"
									onClick={(e) => setDiscplinaryID(e.target.value)}
									hidden
								/>
							</label>

							<label
								className={
									disciplinaryID === "4"
										? selectedButtonClassName + " reportActionButtons-selected"
										: selectedButtonClassName
								}
								htmlFor="forSuspension"
							>
								Suspended for future employement
								<input
									type="radio"
									id="forSuspension"
									name="discipline"
									value="4"
									onClick={(e) => setDiscplinaryID(e.target.value)}
									hidden
								/>
							</label>
						</div>

						<div className="d-flex justify-content-end">
							<button
								className="btn btn-danger text-white px-5 me-4"
								type="submit"
							>
								{loading ? (
									<>
										<Spinner
											as="span"
											animation="grow"
											size="sm"
											role="status"
											aria-hidden="true"
										/>{" "}
										Reporting
									</>
								) : (
									<>Report</>
								)}
							</button>
							<button
								className="btn btn-outline-secondary px-5"
								type="button"
								onClick={props.onHide}
							>
								Cancel
							</button>
						</div>
					</form>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ReportUser;
