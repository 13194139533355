import "./EmployeesTable.scss";
import EmployeesTableRow from "../EmployeesTableRow/EmployeesTableRow";

const EmployeesTable = ({ data }) => {
	return (
		<table className="table table-stripped bg-white">
			<thead>
				<tr>
					<th className="">Name</th>
					<th className="">Job Title</th>
					<th className="">Document Type</th>
					<th className="">Document ID</th>
					<th className="">View Document </th>
					<th className="">Status</th>
					<th className=""></th>
				</tr>
			</thead>
			<tbody>
				{data.length !== 0 ? (
					data.map((employee, index) => (
						<EmployeesTableRow
							key={index}
							vdID={employee.VDID}
							userID={employee.UserID}
							imageID={employee.UserImageID}
							firstname={employee.Firstname}
							lastname={employee.Lastname}
							jobTitle={employee.JobTitle}
							documentType={employee.DocumentTypeName}
							documentID={employee.DocumentNumber}
							document={employee.ImageData}
							status={employee.VerificationStatusID}
							role={employee.RoleID}
						/>
					))
				) : (
					<tr>
						<td colSpan="5">
							<span className="text-info fs-4 text-center">
								No record for this user
							</span>
						</td>
					</tr>
				)}
			</tbody>
		</table>
	);
};

export default EmployeesTable;
