import { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import { voteCase, updateVote } from "../../ApiServices/Services/CaseService";

const ReviewCase = (props) => {
	const [loading, setLoading] = useState(false);
	const [disciplinaryID, setDiscplinaryID] = useState(
		props.userselectedaction ?? 0
	);

	const selectedButtonClassName =
		"d-flex align-items-center justify-content-center col-6 col-xl-3 p-2 border-2 rounded text-center mb-3 hoverable reportActionButtons";

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			if (!props.userselectedaction) {
				await voteCase({
					ReportID: props.reportid,
					ProposedActionID: disciplinaryID,
					Details: "",
				});
			} else {
				await updateVote({
					ReportAdviceID: props.votedcaseid,
					ProposedActionID: disciplinaryID,
				});
			}
			window.location.reload(false);
		} catch (error) {
			alert("Erorr votting on the case, try again ");
			setLoading(false);
		}
	};

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<h2 className="fw-bold">
					Review
					<span className="text-primary ">
						{" " + props.firstname + " " + props.lastname}
					</span>
				</h2>
			</Modal.Header>

			<Modal.Body>
				<form onSubmit={handleSubmit}>
					<div className="mb-4">
						<div className="d-flex justify-content-between">
							<span className="fw-bold">What was the problem </span>
							<Link to="" className="text-primary text-decoration-none">
								Ask for more details
							</Link>
						</div>
						<p>{props.problem}</p>
					</div>

					<div className="">
						<span className="fw-bold">{`${props.firstname}'s`} version </span>
						<p>
							{props.userstory ?? (
								<>This user has not given their side of the story</>
							)}
						</p>
					</div>

					<p className="fw-bold">
						Proposed disciplinary action:{" "}
						<span className="text-danger">{props.sentenceproposed}</span>
					</p>

					<p className="fw-bold">Vote for displinary action</p>
					<div className="row d-flex align-items-center justify-content-between mb-4 px-2">
						<label
							className={
								disciplinaryID === "1"
									? selectedButtonClassName + " reportActionButtons-selected"
									: selectedButtonClassName
							}
							htmlFor="forForgive"
						>
							Forgive person
							<input
								type="radio"
								id="forForgive"
								name="discipline"
								value="1"
								onClick={(e) => setDiscplinaryID(e.target.value)}
								hidden
							/>
						</label>

						<label
							className={
								disciplinaryID === "2"
									? selectedButtonClassName + " reportActionButtons-selected"
									: selectedButtonClassName
							}
							htmlFor="forIndustrialWarning"
						>
							Industrial warning
							<input
								type="radio"
								id="forIndustrialWarning"
								name="discipline"
								value="2"
								onClick={(e) => setDiscplinaryID(e.target.value)}
								hidden
							/>
						</label>

						<label
							className={
								disciplinaryID === "3"
									? selectedButtonClassName + " reportActionButtons-selected"
									: selectedButtonClassName
							}
							htmlFor="forBarred"
						>
							Barred for future employment in the industry
							<input
								type="radio"
								id="forBarred"
								name="discipline"
								value="3"
								onClick={(e) => setDiscplinaryID(e.target.value)}
								hidden
							/>
						</label>

						<label
							className={
								disciplinaryID === "4"
									? selectedButtonClassName + " reportActionButtons-selected"
									: selectedButtonClassName
							}
							htmlFor="forSuspension"
						>
							Suspended for future employement
							<input
								type="radio"
								id="forSuspension"
								name="discipline"
								value="4"
								onClick={(e) => setDiscplinaryID(e.target.value)}
								hidden
							/>
						</label>
					</div>

					<div className="d-sm-flex justify-content-between align-items-center">
						{/* <div>
							<span className="text-secondary">
								Expected decision date:{" "}
								<span className="text-primary">18.12.2022</span>
							</span>
						</div> */}
						<button className="btn btn-primary text-white px-4 me-3">
							{loading ? (
								<>
									<Spinner
										as="span"
										animation="grow"
										size="sm"
										role="status"
										aria-hidden="true"
									/>{" "}
									Voting...
								</>
							) : props.casetype === "open" ? (
								<>Vote</>
							) : (
								<>Revote</>
							)}
						</button>
						<button
							className="btn btn-secondary px-4"
							onClick={props.onHide}
							type="button"
						>
							Cancel
						</button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
};

export default ReviewCase;
