import ChangePasswordForm from "./ChangePasswordForm";

const ResetPassword = () => {
	return (
		<div className="form-page d-flex justify-content-center align-items-center">
			<div className="form-container bg-white pt-5 pb-3 px-sm-5 px-3 ">
				<ChangePasswordForm forReset />
			</div>
		</div>
	);
};

export default ResetPassword;
