import "./NavbarComponent.scss";
import { Container, Navbar, Nav } from "react-bootstrap";
import { BsFillBellFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { getUser } from "../../ApiServices/Services/UserService";
import auth from "../../ApiServices/Services/AuthService";
import UserAvatar from "../../images/useravatar.png";
import Logout from "../Modals/Logout";
import { fetchUserUnseenNotifications } from "../../ApiServices/Services/NotificationService";

const NavbarComponent = () => {
	const userID = auth.getCurrentUserID();
	const userRole = auth.getCurrentUserRole();
	const companyID = auth.getCurrentUserCompany();
	const [unSeenNotifications, setUnSeenNotifications] = useState(0);
	const [userImage, setUserImage] = useState("");
	const [logoutModal, setLogoutModal] = useState(false);

	useEffect(() => {
		const fetchUser = async () => {
			const { data } = await getUser(userID);
			setUserImage(data.obj[0].UserImageID);
		};
		fetchUser();
	}, [userID]);

	useEffect(() => {
		const fetchUser = async () => {
			const res = await fetchUserUnseenNotifications();
			setUnSeenNotifications(res);
		};
		fetchUser();
	}, [userID]);

	return (
		<>
			<Logout show={logoutModal} onHide={() => setLogoutModal(false)} />

			<Navbar bg="white" expand="md" sticky="top">
				<Container fluid>
					<Navbar.Brand>
						<Link to="/" className="brand">
							SEAFARERS REGISTER
						</Link>
					</Navbar.Brand>
					<Navbar.Toggle />
					<Navbar.Collapse id="navbarScroll" className="">
						<Nav className="me-auto "></Nav>
						<Nav className="d-flex align-items-center">
							<Link to="/" className="links">
								Home
							</Link>
							{(userRole === "3" || userRole === "4") && (
								<Link to="/companies" className="links">
									Companies
								</Link>
							)}
							{userRole === "2" && (
								<>
									<Link to={`/employees/${companyID}`} className="links">
										Employees
									</Link>
									<Link to={`/company/${companyID}`} className="links">
										Company Profile
									</Link>
								</>
							)}
							{(userRole === "3" || userRole === "4") && (
								<Link to="/cases" className="links">
									Cases
								</Link>
							)}
							<button
								className="btn btn-danger text-white links"
								onClick={() => setLogoutModal(true)}
							>
								Logout
							</button>
							<Link to="/notifications">
								<div className="notification-badge">
									<span className="icon">
										{" "}
										<BsFillBellFill />{" "}
									</span>
									<span className="badge">{unSeenNotifications}</span>
								</div>
							</Link>
							<Link to={`/profile/${userID}`} className="">
								<img
									src={
										userImage
											? `${process.env.REACT_APP_API_URL}/imgw/userimage/${userImage}/100`
											: UserAvatar
									}
									alt=""
									className="image"
								/>
							</Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</>
	);
};

export default NavbarComponent;
