import { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { updateUser } from "../../ApiServices/Services/UserService";

const AddBio = () => {
	let navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [about, setAbout] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			await updateUser({ About: about });
			setLoading(false);
			navigate("/addexperience");
		} catch (Error) {
			alert("Unable to submit data, try again");
			setLoading(false);
		}
	};

	return (
		<div className="form-page d-flex justify-content-center align-items-center">
			<div className="add-profile form-container bg-white pt-5 pb-3 px-sm-4 px-3 ">
				<div className="progresss d-flex justify-content-center">
					<span className="active"></span>
					<span></span>
					<span></span>
					<span></span>
				</div>
				<h3 className="text-center fw-bold mb-3">About you</h3>

				<form onSubmit={handleSubmit}>
					<div className="">
						<label htmlFor="bio" className="fw-bold  mb-2">
							Your bio
						</label>
						<textarea
							className="form-control mb-4"
							id="bio"
							rows="5"
							placeholder="Write your bio here...
                            "
							value={about}
							onChange={(e) => setAbout(e.target.value)}
						/>

						<button className="btn btn-primary text-white px-5">
							{loading ? (
								<>
									<Spinner
										as="span"
										animation="grow"
										size="sm"
										role="status"
										aria-hidden="true"
									/>{" "}
									Saving
								</>
							) : (
								<>Save</>
							)}
						</button>
					</div>
				</form>
				<div className="d-flex justify-content-end mt-n3">
					<Link className="btn btn-secondary px-5 mt-n4" to="/addexperience">
						Skip
					</Link>
				</div>
			</div>
		</div>
	);
};

export default AddBio;
