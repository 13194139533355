import "./EmployeesTableRow.scss";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Badge } from "react-bootstrap";
import UserAvatar from "../../images/useravatar.png";
import ViewImage from "./../Modals/ViewImage";
import EditEmployeeStatus from "./../Modals/EditEmployeeStatus";
import auth from "../../ApiServices/Services/AuthService";
import MakeCompanyAdmin from "./../Modals/MakeCompanyAdmin";

const EmployeesTableRow = ({
	userID,
	vdID,
	imageID,
	firstname,
	lastname,
	jobTitle,
	documentType,
	documentID,
	document,
	status,
	role,
}) => {
	const [showImageModal, setShowImageModal] = useState(false);
	const [editStatusModal, setEditStatusModal] = useState(false);
	const [makeAdmin, setMakeAdmin] = useState(false);

	return status === "0" ? null : (
		<>
			<tr>
				<td className="">
					<img
						src={
							imageID
								? `${process.env.REACT_APP_API_URL}/imgw/userimage/${imageID}/100`
								: UserAvatar
						}
						alt=""
					/>
					<span className="">
						<Link to={`/profile/${userID}`}>{firstname + " " + lastname}</Link>
					</span>
				</td>
				<td className="">{jobTitle}</td>
				<td className="">{documentType}</td>
				<td className="">{documentID}</td>
				<td className="">
					<p
						className="text-primary"
						role="button"
						onClick={() => setShowImageModal(true)}
					>
						click to view
					</p>
				</td>
				<td>
					{status ? (
						<>
							<Badge pill bg="success">
								Approved
							</Badge>{" "}
							/{" "}
							{role === "2" ? (
								<Badge pill bg="info">
									Admin
								</Badge>
							) : (
								<Badge pill bg="secondary">
									Employee
								</Badge>
							)}
						</>
					) : (
						<Badge pill bg="warning">
							Not approved
						</Badge>
					)}
				</td>

				<td>
					{(auth.getCurrentUserRole() === "2" ||
						auth.getCurrentUserRole() === "4") &&
						status === "" && (
							<>
								<button
									className="btn btn-sm btn-primary me-1 text-white"
									onClick={() => setEditStatusModal(true)}
								>
									edit status
								</button>
							</>
						)}
					{(auth.getCurrentUserRole() === "2" ||
						auth.getCurrentUserRole() === "4") &&
						role !== "2" && (
							<>
								<button
									className="btn btn-sm btn-primary text-white"
									onClick={() => setMakeAdmin(true)}
								>
									make admin
								</button>
							</>
						)}
				</td>
			</tr>

			<ViewImage
				show={showImageModal}
				onHide={() => setShowImageModal(false)}
				documentid={vdID}
			/>

			<EditEmployeeStatus
				show={editStatusModal}
				onHide={() => setEditStatusModal(false)}
				firstname={firstname}
				vdid={vdID}
			/>

			<MakeCompanyAdmin
				show={makeAdmin}
				onHide={() => setMakeAdmin(false)}
				firstname={firstname}
			/>
		</>
	);
};

EmployeesTableRow.defaultProps = {
	imgSrc: UserAvatar,
};

export default EmployeesTableRow;
