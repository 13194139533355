import Home from "./Pages/Home/Home";
import Profile from "./Pages/Profile/Profile";
import Cases from "./Pages/Cases/Cases";
import Employees from "./Pages/Employees/Employees";
import NotFound from "./Pages/NotFound/NotFound";
import Login from "./Pages/Forms/Login";
import Signup from "./Pages/Forms/Signup";
import ConfirmEmail from "./Pages/Forms/ConfirmEmail";
import ConfirmIdentity from "./Pages/Forms/ConfirmIdentity";
import ForgotPassword from "./Pages/Forms/ForgotPassword";
import ConfirmAccount from "./Pages/Forms/ConfirmAccount";
import AddProfilePicture from "./Pages/Forms/AddProfilePicture";
import AddBio from "./Pages/Forms/AddBio";
import AddWorkExperience from "./Pages/Forms/AddWorkExperience";
import AddEducation from "./Pages/Forms/AddEducation";
import CompanySignup from "./Pages/Forms/CompanySignup";
import CompanyProfile from "./Pages/CompanyProfile/CompanyProfile";
import Notification from "./Pages/Notifications/Notification";
import Companies from "./Pages/Companies/Companies";
import ResetPassword from "./Pages/Forms/ResetPassword";
import ViewClosedCase from "./Pages/Cases/ViewClosedCase";

const routes = [
	{
		name: "Home",
		path: "/",
		element: Home,
		layout: true,
		protected: true,
	},
	{
		name: "Profile",
		path: "/profile/:id",
		element: Profile,
		layout: true,
		protected: true,
	},
	{
		name: "Cpmpany Profile",
		path: "/company/:id",
		element: CompanyProfile,
		layout: true,
		protected: true,
	},
	{
		name: "Companies",
		path: "/companies",
		element: Companies,
		layout: true,
		protected: true,
	},
	{
		name: "Employees",
		path: "/employees/:companyID",
		element: Employees,
		layout: true,
		protected: true,
	},
	{
		name: "Cases",
		path: "/cases",
		element: Cases,
		layout: true,
		protected: true,
	},
	{
		name: "Notifications",
		path: "/notifications",
		element: Notification,
		layout: true,
		protected: true,
	},
	{
		name: "NotFound",
		path: "/*",
		element: NotFound,
		layout: true,
		protected: true,
	},
	{
		name: "Login",
		path: "/login",
		element: Login,
		layout: false,
		protected: false,
	},
	{
		name: "SignUp",
		path: "/signup",
		element: Signup,
		layout: false,
		protected: false,
	},
	{
		name: "Company SignUp",
		path: "/companysignup",
		element: CompanySignup,
		layout: false,
		protected: false,
	},
	{
		name: "Confirm Email",
		path: "/confirmemail/:type",
		element: ConfirmEmail,
		layout: false,
		protected: true,
	},
	{
		name: "Confirm Identity",
		path: "/confirmidentity",
		element: ConfirmIdentity,
		layout: false,
		protected: true,
	},
	{
		name: "Confirm Account",
		path: "/confirmaccount/:code",
		element: ConfirmAccount,
		layout: false,
		protected: false,
	},
	{
		name: "Forgot Password",
		path: "/forgotpassword",
		element: ForgotPassword,
		layout: false,
		protected: false,
	},
	{
		name: "Reset Password",
		path: "/resetpassword",
		element: ResetPassword,
		layout: false,
		protected: true,
	},
	{
		name: "Upload Profile Picture",
		path: "/uploadpicture",
		element: AddProfilePicture,
		layout: false,
		protected: true,
	},
	{
		name: "Add Bio to profile",
		path: "/addbio",
		element: AddBio,
		layout: false,
		protected: true,
	},
	{
		name: "Add Work Experience to profile",
		path: "/addexperience",
		element: AddWorkExperience,
		layout: false,
		protected: true,
	},
	{
		name: "Add Education to profile",
		path: "/addeducation",
		element: AddEducation,
		layout: false,
		protected: true,
	},
	{
		name: "View closed case",
		path: "/viewclosedcase/:closedCaseId",
		element: ViewClosedCase,
		layout: true,
		protected: true,
	},
];

export default routes;
