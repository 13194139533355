import { BsPlusLg } from "react-icons/bs";
import { RiArrowDownSLine, RiEdit2Line } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import AddEducationForm from "./../Forms/AddEducationForm";

const AddEducationModal = (props) => {
	return (
		<Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter">
			<div className="bg-white rounded p-sm-3 p-2">
				<div className="mb-3" style={{ position: "relative" }}>
					<h3 className="fw-bold">Add education</h3>
					<button
						onClick={props.onHide}
						className="btn"
						style={{
							position: "absolute",
							top: "0",
							right: "0",
							fontSize: "20px",
							cursor: "pointer",
						}}
					>
						<AiOutlineClose />{" "}
					</button>
				</div>

				<hr />

				<AddEducationForm shouldReload={true} />

				<div className="d-flex justify-content-end mt-n3">
					<button
						className="btn btn-danger px-sm-5 mt-n4"
						onClick={props.onHide}
					>
						Cancel
					</button>
				</div>
			</div>
		</Modal>
	);
};

const EditEducation = (props) => {
	return (
		<Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter">
			<div className="bg-white rounded p-sm-3 p-2">
				<div className="mb-3" style={{ position: "relative" }}>
					<h3 className="fw-bold">Edit education</h3>
					<button
						onClick={props.onHide}
						className="btn"
						style={{
							position: "absolute",
							top: "0",
							right: "0",
							fontSize: "20px",
							cursor: "pointer",
						}}
					>
						<AiOutlineClose />{" "}
					</button>
				</div>

				<hr />

				<AddEducationForm shouldReload={true} previousData={props.data} />

				<div className="d-flex justify-content-end mt-n3">
					<button
						className="btn btn-danger px-sm-5 mt-n4"
						onClick={props.onHide}
					>
						Cancel
					</button>
				</div>
			</div>
		</Modal>
	);
};

const ProfileEducationList = (props) => {
	const [editEducationModal, setEditEducationModal] = useState(false);

	return (
		<>
			<EditEducation
				show={editEducationModal}
				onHide={() => setEditEducationModal(false)}
				data={props}
			/>

			<div className="mb-3">
				<div className=" d-flex justify-content-between align-items-start ">
					<div className="d-flex flex-column">
						<span className="fw-bold">
							{props.InstitutionName} - {props.Qualification} {props.Discipline}
						</span>
						<small className="text-muted">
							{props.StartDate} - {props.EndDate}
						</small>
					</div>
					{props.userOwnsProfile && (
						<button className="btn" onClick={() => setEditEducationModal(true)}>
							<RiEdit2Line className="action-icons text-primary fs-4" />
						</button>
					)}
				</div>
				<p className="mt-2">{props.Details}</p>
			</div>
		</>
	);
};

const ProfileEducation = ({ data, userOwnsProfile }) => {
	const [addEducationModal, setAddEducationModal] = useState(false);
	return (
		<>
			<AddEducationModal
				show={addEducationModal}
				onHide={() => setAddEducationModal(false)}
			/>

			<div className="profile-education p-2 px-sm-4 mt-3">
				<div className="profile-education-head d-flex justify-content-between align-items-start">
					<p className="fw-bold fs-4">Education </p>
					{userOwnsProfile && (
						<button className="btn" onClick={() => setAddEducationModal(true)}>
							<BsPlusLg className="text-primary fs-5" />
						</button>
					)}
				</div>
				<div className="profile-education-body ps-2">
					{data.length > 0 ? (
						data.map((edu) => (
							<ProfileEducationList
								key={edu.EducationID}
								EID={edu.EducationID}
								InstitutionName={edu.InstitutionName}
								Qualification={edu.Qualification}
								Discipline={edu.discipline}
								Details={edu.details}
								StartDate={edu.StartDate}
								EndDate={edu.EndDate}
								userOwnsProfile={userOwnsProfile}
							/>
						))
					) : (
						<div>
							<p>No education added</p>
						</div>
					)}

					{data.length > 2 && (
						<button className="btn text-primary ps-0">
							show more <RiArrowDownSLine />
						</button>
					)}
				</div>
			</div>
		</>
	);
};

export default ProfileEducation;
