import http, { axiosJsonToPost } from "../Utils/HttpService";
import auth from "./AuthService";

const user = auth.getCurrentUserID();
const usersCompany = auth.getCurrentUserCompany();

const apiEndPointSendEmailToken = "/token";
const apiEndPointVerifyUser = "/verifytoken";
const apiEndPointFetch = "/fetch/vwuser";
const apiEndPointFetchUserP = "/fetch/user"; //this is used to check for user password since its not included in vwuser
const apiEndPointSave = "/save/user";
const apiEndPointUpdate = "/update/user";
const apiEndPointSaveDisplayImage = "/save/userimage";
const apiEndPointUpdateDisplayImage = "/update/userimage";
const apiEndPointDeleteDisplayImage = "/delete/userimage";
const apiEdnPointSaveIdentityDocument = "/save/verificationdocument";
const apiEndpointSaveWorkExperience = "/save/workexperience";
const apiEndpointUpdateWorkExperience = "/update/workexperience";
const apiEndpointSaveEducation = "/save/education";
const apiEndpointUpdateEducation = "/update/education";
const apiEndpointSetUserStatus = "/save/verificationapproval";

export function getUsers() {
	return http.get(apiEndPointFetch);
}

export function getUser(userId) {
	return http.get(`${apiEndPointFetch}/${userId}`);
}

export function saveUser(userId) {
	return http.post(apiEndPointSave, axiosJsonToPost(userId));
}

export function fetchUserIdByEmail(email) {
	return http.post(apiEndPointFetchUserP, axiosJsonToPost({ Email: email }));
}

export function sendEmailToken() {
	return http.post(
		apiEndPointSendEmailToken,
		axiosJsonToPost({ UserID: user })
	);
}

export function verifyUser(token) {
	return http.post(
		apiEndPointVerifyUser,
		axiosJsonToPost({ UserID: user, Token: token })
	);
}

export function updateUser(details) {
	return http.post(
		apiEndPointUpdate,
		axiosJsonToPost({ UserID: user, ...details })
	);
}

export function saveWorkExperience(details) {
	return http.post(
		apiEndpointSaveWorkExperience,
		axiosJsonToPost({ UserID: user, ...details })
	);
}

export function updateWorkExperience(details) {
	return http.post(apiEndpointUpdateWorkExperience, axiosJsonToPost(details));
}

export function saveEducation(details) {
	return http.post(
		apiEndpointSaveEducation,
		axiosJsonToPost({ UserID: user, ...details })
	);
}

export function updateEducation(details) {
	return http.post(apiEndpointUpdateEducation, axiosJsonToPost(details));
}

export function saveIdentityDocument(details) {
	details.append("UserID", user);
	details.append("CompanyID", usersCompany);
	return http.post(apiEdnPointSaveIdentityDocument, details, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
}

export function saveDisplayPicture(imageData) {
	imageData.append("UserID", user);
	return http.post(apiEndPointSaveDisplayImage, imageData);
}

export async function updateDisplayPicture(imageData) {
	return http.post(apiEndPointUpdateDisplayImage, imageData);
}

export function deleteUserImage(userImageId) {
	return http.post(
		apiEndPointDeleteDisplayImage,
		axiosJsonToPost({ UserImageID: userImageId })
	);
}

export function verifyUserPassword(password) {
	return http.post(
		apiEndPointFetchUserP,
		axiosJsonToPost({ UserID: user, Password: password })
	);
}

export function setEmployeeStatus(details) {
	return http.post(
		apiEndpointSetUserStatus,
		axiosJsonToPost({ AdminID: user, ...details })
	);
}

export function makeCompanyAdmin() {
	return http.post(
		apiEndPointUpdate,
		axiosJsonToPost({ UserID: user, RoleID: "2" })
	);
}
