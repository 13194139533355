import { BsPlusLg } from "react-icons/bs";
import { RiArrowDownSLine, RiEdit2Line } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import AddWorkExperienceForm from "../Forms/AddWorkExperienceForm";

const AddWorkExperienceModal = (props) => {
	return (
		<Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter">
			<div className="bg-white rounded p-sm-3 p-2">
				<div className="mb-3" style={{ position: "relative" }}>
					<h3 className="fw-bold">Add work experience</h3>
					<button
						onClick={props.onHide}
						className="btn"
						style={{
							position: "absolute",
							top: "0",
							right: "0",
							fontSize: "20px",
							cursor: "pointer",
						}}
					>
						<AiOutlineClose />{" "}
					</button>
				</div>

				<hr />

				<AddWorkExperienceForm shouldReload={true} />

				<div className="d-flex justify-content-end mt-n3">
					<button
						className="btn btn-danger px-sm-5 mt-n4"
						onClick={props.onHide}
					>
						Cancel
					</button>
				</div>
			</div>
		</Modal>
	);
};

const EditWorkExperience = (props) => {
	return (
		<Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter">
			<div className="bg-white rounded p-sm-3 p-2">
				<div className="mb-3" style={{ position: "relative" }}>
					<h3 className="fw-bold">Edit work experience</h3>
					<button
						onClick={props.onHide}
						className="btn"
						style={{
							position: "absolute",
							top: "0",
							right: "0",
							fontSize: "20px",
							cursor: "pointer",
						}}
					>
						<AiOutlineClose />{" "}
					</button>
				</div>

				<hr />

				<AddWorkExperienceForm shouldReload={true} previousData={props.data} />

				<div className="d-flex justify-content-end mt-n3">
					<button
						className="btn btn-danger px-sm-5 mt-n4"
						onClick={props.onHide}
					>
						Cancel
					</button>
				</div>
			</div>
		</Modal>
	);
};

const ProfileWorkExperienceList = (props) => {
	const [editWorkExperienceModal, setEditWorkExperienceModal] = useState(false);
	return (
		<>
			<EditWorkExperience
				show={editWorkExperienceModal}
				onHide={() => setEditWorkExperienceModal(false)}
				data={props}
			/>

			<div className="mb-3">
				<div className=" d-flex justify-content-between align-items-start ">
					<div className="d-flex flex-column">
						<span className="fw-bold">{props.JobTitle} </span>
						<span>{props.CompanyName}</span>
						<small className="text-muted">
							{props.StartDate} to {props.EndDate}
						</small>
					</div>
					{props.userOwnsProfile && (
						<button
							className="btn"
							onClick={() => setEditWorkExperienceModal(true)}
						>
							<RiEdit2Line className="action-icons text-primary fs-4" />
						</button>
					)}
				</div>
				<p className="mt-2">{props.Responsibility}</p>
			</div>
		</>
	);
};

const ProfileWorkExperience = ({ data, userOwnsProfile }) => {
	const [addWorkExperienceModel, setAddWorkExperienceModel] = useState(false);

	return (
		<>
			<AddWorkExperienceModal
				show={addWorkExperienceModel}
				onHide={() => setAddWorkExperienceModel(false)}
			/>

			<div className="profile-work p-2 px-sm-4 mt-3">
				<div className="profile-work-head d-flex justify-content-between align-items-start">
					<p className="fw-bold fs-4">Work Experience </p>
					{userOwnsProfile && (
						<button
							className="btn "
							onClick={() => setAddWorkExperienceModel(true)}
						>
							<BsPlusLg className="text-primary fs-5" />
						</button>
					)}
				</div>
				<div className="profile-work-body ps-2">
					{data.length > 0 ? (
						data.map((we) => (
							<ProfileWorkExperienceList
								key={we.WEID}
								WEID={we.WEID}
								CompanyName={we.CompanyName}
								Responsibility={we.Responsibility}
								StartDate={we.StartDate}
								EndDate={we.EndDate}
								JobTitle={we.JobTitle}
								userOwnsProfile={userOwnsProfile}
							/>
						))
					) : (
						<div>
							<p>
								No work experience, click on the plus icon to add work
								experience{" "}
							</p>
						</div>
					)}

					{data.length > 2 && (
						<button className="btn text-primary ps-0">
							show more <RiArrowDownSLine />
						</button>
					)}
				</div>
			</div>
		</>
	);
};

export default ProfileWorkExperience;
