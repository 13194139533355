import { useState, useEffect } from "react";
import Select from "react-select";
import { Spinner } from "react-bootstrap";
import {
	createCompany,
	updateCompany,
	fetchSectors,
} from "../../ApiServices/Services/CompanyService";
import Loading from "../../Components/Loading";

const CompanyForm = ({ data: company, newData }) => {
	const [loading, setLoading] = useState(false);
	const [about, setAbout] = useState(company?.About ?? "");
	const [motto, setMotto] = useState(company?.Moto ?? "");
	const [categoryId, setCategoryId] = useState(company?.CompanyID ?? "");
	const [size, setSize] = useState(company?.CompanySize ?? "");
	const [year, setYear] = useState(company?.YearFounded ?? "");
	const [location, setLocation] = useState(company?.Address ?? "");
	const [website, setWebsite] = useState(company?.Website ?? "");
	const [contact, setContact] = useState(company?.Contact ?? "");
	const [sectors, setSectors] = useState("");

	useEffect(() => {
		const getSectors = async () => {
			const { data } = await fetchSectors();
			const sectors = data.obj.map((sector) => ({
				label: sector.SectorName,
				value: sector.SectorID,
			}));
			setSectors(sectors);
		};
		getSectors();
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		const details = {
			Moto: motto,
			About: about,
			CategoryID: categoryId.value,
			CompanySize: size,
			YearFounded: year,
			Address: location,
			Website: website,
			Contact: contact,
		};

		if (newData) {
			try {
				await createCompany(details);
				window.location.reload(false);
			} catch (Error) {
				alert("Unable to submit data, try again");
				setLoading(false);
			}
		} else {
			try {
				await updateCompany({ CompanyID: company.CompanyID, ...details });
				window.location.reload(false);
			} catch (Error) {
				alert("Unable to submit data, try again");
				setLoading(false);
			}
		}
	};

	return sectors === "" ? (
		<Loading />
	) : (
		<form onSubmit={handleSubmit}>
			<div className="row mb-3">
				<div className="form-group mb-3">
					<label htmlFor="about">About</label>
					<textarea
						className="form-control"
						id="about"
						rows="5"
						placeholder="Write your bio here..."
						value={about}
						onChange={(e) => setAbout(e.target.value)}
					/>
				</div>
				<div className="form-group mb-3">
					<label htmlFor="motto">Motto</label>
					<input
						type="text"
						id="motto"
						className="form-control"
						value={motto}
						onChange={(e) => setMotto(e.target.value)}
					/>
				</div>
				<div className="form-group mb-3 col-md-6">
					<label htmlFor="industryCategory">Industry Category</label>
					<Select
						options={sectors}
						placeholder="Select category"
						value={categoryId}
						onChange={(e) => setCategoryId(e)}
					/>
				</div>
				<div className="form-group mb-3 col-md-6">
					<label htmlFor="companySize">Company Size</label>
					<input
						type="text"
						id="companySize"
						className="form-control"
						value={size}
						onChange={(e) => setSize(e.target.value)}
					/>
				</div>
				<div className="form-group mb-3 col-md-6">
					<label htmlFor="yearFounded">Year Founded</label>
					<input
						type="text"
						id="yearFounded"
						className="form-control"
						value={year}
						onChange={(e) => setYear(e.target.value)}
					/>
				</div>
				<div className="form-group mb-3 col-md-6">
					<label htmlFor="location">Location</label>
					<input
						type="text"
						id="location"
						className="form-control"
						value={location}
						onChange={(e) => setLocation(e.target.value)}
					/>
				</div>
				<div className="form-group mb-3 col-md-6">
					<label htmlFor="website">Website</label>
					<input
						type="text"
						id="website"
						className="form-control"
						value={website}
						onChange={(e) => setWebsite(e.target.value)}
					/>
				</div>
				<div className="form-group mb-3 col-md-6">
					<label htmlFor="contactNumber">Contact Number</label>
					<input
						type="text"
						id="contactNumber"
						className="form-control"
						value={contact}
						onChange={(e) => setContact(e.target.value)}
					/>
				</div>
			</div>

			<button className="btn btn-primary text-white px-sm-5">
				{loading ? (
					<>
						<Spinner
							as="span"
							animation="grow"
							size="sm"
							role="status"
							aria-hidden="true"
						/>{" "}
						Saving
					</>
				) : (
					<>Save</>
				)}
			</button>
		</form>
	);
};

export default CompanyForm;
