import "./UserCase.scss";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import ReviewCase from "../Modals/ReviewCase";
import CloseCase from "../Modals/CloseCase";
import { getUser } from "../../ApiServices/Services/UserService";
import auth from "./../../ApiServices/Services/AuthService";
import UserAvatar from "../../images/useravatar.png";

const UserCase = ({
	reportId,
	userID,
	firstname,
	lastname,
	JobTitle,
	CompanyID,
	CompanyName,
	problem,
	sentenceProposed,
	userStory,
	caseType,
	userSelectedAction,
	votedCaseId,
}) => {
	const viewersRole = auth.getCurrentUserRole();
	const [user, setUser] = useState("");
	const [voteCaseModal, setVoteCaseModal] = useState(false);
	const [closeCaseModal, setCloseCaseModal] = useState(false);

	useEffect(() => {
		const fetchUser = async () => {
			const { data } = await getUser(userID);
			setUser(data.obj[0]);
		};
		fetchUser();
	}, [userID]);

	return (
		<>
			<ReviewCase
				show={voteCaseModal}
				onHide={() => setVoteCaseModal(false)}
				reportid={reportId}
				problem={problem}
				firstname={firstname}
				lastname={lastname}
				sentenceproposed={sentenceProposed}
				userstory={userStory}
				casetype={caseType}
				userselectedaction={userSelectedAction}
				votedcaseid={votedCaseId}
			/>
			<CloseCase
				show={closeCaseModal}
				onHide={() => setCloseCaseModal(false)}
				reportid={reportId}
				problem={problem}
				firstname={firstname}
				lastname={lastname}
				sentenceproposed={sentenceProposed}
				userstory={userStory}
			/>

			<div>
				<div className="single-case hoverable">
					<div className="card-head">
						<div className="user-image">
							<img
								src={
									user.UserImageID
										? `${process.env.REACT_APP_API_URL}/imgw/userimage/${user.UserImageID}/100`
										: UserAvatar
								}
								alt=""
							/>
						</div>
						<div className="user-details">
							<p className="name">
								<strong>
									<Link to={`/profile/${userID}`}>
										{firstname + " " + lastname}
									</Link>
								</strong>
							</p>
							<p>
								<span className="role">{JobTitle} at</span>
								<br />
								<span className="company">
									<Link to={`/company/${CompanyID}`}>{CompanyName}</Link>
								</span>
							</p>
						</div>
					</div>

					<div className="card-bodyy">
						<p>
							<strong>What was the problem:</strong>
							<br />
							{problem}
						</p>
						<p>
							<strong>The sentence proposed:</strong>
							<br />
							{sentenceProposed}
						</p>
					</div>

					{viewersRole === "3" &&
						(caseType === "open" ? (
							<button
								onClick={() => setVoteCaseModal(true)}
								className="btn btn-primary w-100 text-white"
							>
								vote
							</button>
						) : (
							<div className="d-flex w-100 justify-content-between">
								<button
									onClick={() => setVoteCaseModal(true)}
									className="btn btn-primary  text-white"
								>
									Revote
								</button>
								<button
									onClick={() => setCloseCaseModal(true)}
									className="btn btn-danger  text-white"
								>
									Close case
								</button>
							</div>
						))}
				</div>
			</div>
		</>
	);
};

export default UserCase;
