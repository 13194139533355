import { Modal } from "react-bootstrap";
import CompanyForm from "../../Pages/Forms/CompanyForm";

const EditCompanyProfile = (props) => {
	return (
		<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
			<Modal.Header closeButton>
				<h3 className="fw-bold">Edit company profile</h3>
			</Modal.Header>

			<Modal.Body>
				<div className="bg-white rounded">
					<CompanyForm data={props.company} />

					<div className="d-flex justify-content-end mt-n3">
						<button
							className="btn btn-danger px-sm-5 mt-n4"
							onClick={props.onHide}
						>
							Cancel
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default EditCompanyProfile;
