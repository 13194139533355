import "./UserList.scss";
import User from "../../Components/User/User";

const UsersList = ({ data }) => {
	if (data.length === 0) {
		return (
			<div className="mt-n2">
				<h3 className="text-center text-danger">
					<em>User not found</em>
				</h3>
			</div>
		);
	} else {
		return (
			<div className="userlist py-3  px-2 px-sm-5">
				{data &&
					data.map((user, index) => (
						<User
							key={index}
							userId={user.UserID}
							firstname={user.Firstname}
							lastname={user.Lastname}
							jobTitle={user.JobTitle}
							company={user.CompanyName}
							companyID={user.CompanyID}
							userImageId={user.UserImageID}
						/>
					))}
			</div>
		);
	}
};

export default UsersList;
