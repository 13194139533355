import http, { axiosJsonToPost } from "../Utils/HttpService";
import auth from "./AuthService";

const user = auth.getCurrentUserID();
const apiEndPointFetchNotification = "/fetch/notification";

export async function fetchUserUnseenNotifications() {
	const { data } = await http.post(
		apiEndPointFetchNotification,
		axiosJsonToPost({ UserID: user, StatusID: 1 })
	);
	return data.obj.length;
}

export function fetchUserNotifications() {
	return http.post(
		apiEndPointFetchNotification,
		axiosJsonToPost({ UserID: user })
	);
}
