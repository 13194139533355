import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { sendEmailToken } from "../../ApiServices/Services/UserService";

const ResetTokenModal = (props) => {
	const [loading, setLoading] = useState(false);

	const handleResetToken = async () => {
		setLoading(true);

		try {
			await sendEmailToken();
			alert("Email sent ");
			setLoading(false);
			props.onHide();
		} catch (error) {
			alert("Error resetting token, try again ");
			setLoading(false);
		}
	};

	return (
		<Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter">
			<Modal.Header closeButton>
				<h3 className="fw-bold">Reset token</h3>
			</Modal.Header>
			<Modal.Body>
				<p>
					Click the 'Send' button below to send a new token to your email
					address. <br />
					<b>Note: </b> token expires in ten minutes.
				</p>

				<div className="d-flex justify-content-between align-items-center">
					<button
						className="btn btn-primary text-white"
						onClick={handleResetToken}
					>
						{loading ? (
							<>
								<Spinner
									as="span"
									animation="grow"
									size="sm"
									role="status"
									aria-hidden="true"
								/>{" "}
								Sending token...
							</>
						) : (
							<>Send</>
						)}
					</button>

					<button className="btn btn-danger" onClick={props.onHide}>
						Cancel
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ResetTokenModal;
