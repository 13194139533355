import "./Forms.scss";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Spinner } from "react-bootstrap";
import { verifyUser } from "../../ApiServices/Services/UserService";
import ResetTokenModal from "../../Components/Modals/ResetTokenModal";
import useTimeout from "../../Components/Timeout";
import { useParams } from "react-router-dom";

const ConfirmEmail = () => {
	let navigate = useNavigate();
	let { type } = useParams();
	const [loading, setLoading] = useState(false);
	const [token, setToken] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [resetTokenModal, setResetTokenModal] = useState(false);
	const [showResetButton, setShowResetButton] = useState(false);

	useTimeout(() => {
		setShowResetButton(true);
	}, 10000);

	const confirmUserEmail = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			const { data } = await verifyUser(token);
			if (data.status === 0) {
				setErrorMessage(data.Error);
				setLoading(false);
			} else {
				type === "chg"
					? navigate("/resetpassword")
					: navigate("/confirmidentity");
			}
		} catch (Error) {
			alert("Error verifying token");
			setLoading(false);
		}
	};

	return (
		<>
			<ResetTokenModal
				show={resetTokenModal}
				onHide={() => setResetTokenModal(false)}
			/>

			<div className="form-page d-flex justify-content-center align-items-center">
				<div className="form-container bg-white pt-5 pb-3 px-sm-5 px-3 ">
					<div className="progresss d-flex justify-content-center">
						<span className="active"></span>
						<span className="active"></span>
						<span></span>
						<span></span>
					</div>
					<h3 className="text-center fw-bold">Confirm Email</h3>

					<p className="text-muted text-center mb-4">
						Please insert below the code we have sent to your email to
						{type === "chg" ? (
							<> reset your password</>
						) : (
							<> activate your account</>
						)}
						.
					</p>

					<form
						className="confirm-email"
						noValidate
						onSubmit={confirmUserEmail}
					>
						<div>
							<label htmlFor="token" className="fw-bold fs-5 mb-2">
								{type === "chg" ? <>Token</> : <>Activation Token</>}
							</label>
							{errorMessage !== "" && (
								<p className="text-danger fw-bold fs-5">{errorMessage}</p>
							)}
							<input
								type="number"
								id="token"
								className="form-control"
								value={token}
								onChange={(e) => setToken(e.target.value)}
								required
							/>

							{showResetButton && (
								<button
									className="btn mt-2 text-info ps-0"
									type="button"
									onClick={() => setResetTokenModal(true)}
								>
									Click to reset token
								</button>
							)}
						</div>

						<div className="d-grid gap-2 mt-4">
							<button
								className="btn btn-primary text-white fw-bold"
								type="submit"
							>
								{loading ? (
									<>
										<Spinner
											as="span"
											animation="grow"
											size="sm"
											role="status"
											aria-hidden="true"
										/>{" "}
										Confirming
									</>
								) : (
									<>Confirm Email</>
								)}
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default ConfirmEmail;
