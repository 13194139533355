import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Row, Col, Alert } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { getCompanies } from "../../ApiServices/Services/CompanyService";
import auth from "../../ApiServices/Services/AuthService";
import NotFound from "../NotFound/NotFound";
import Loading from "../../Components/Loading";
import CompanyAvatar from "../../images/companyavatar.png";
import AddCompany from "./../../Components/Modals/AddCompany";

const CompaniesList = ({ data }) => {
	if (data.length === 0) {
		return (
			<Alert variant="danger">
				<Alert.Heading>Company not found </Alert.Heading>
				<p>This company is not registered with us.</p>
			</Alert>
		);
	} else {
		return (
			<Row xs={1} md={2} lg={3} xxl={4} className="g-4">
				{data.map((cm) => (
					<Col key={cm.CompanyID}>
						<Card
							className="px-sm-2 px-0 py-3 d-flex justify-content-between align-items-center hoverable"
							style={{ height: "19rem" }}
						>
							<Card.Img
								variant="top"
								src={
									cm.CLID
										? `${process.env.REACT_APP_API_URL}/imgh/companylogo/${cm.CLID}/300`
										: CompanyAvatar
								}
								style={{ height: "10rem", width: "15rem", borderRadius: "5px" }}
							/>
							<Card.Body className="d-flex flex-column justify-content-between align-items-center pb-0">
								<Card.Title className="fs-5">{cm.CompanyName}</Card.Title>
								<div className="">
									<Link
										to={`/company/${cm.CompanyID}`}
										className="btn btn-primary text-white me-2"
									>
										<small>View Profile</small>
									</Link>
									<Link
										to={`/employees/${cm.CompanyID}`}
										className="btn btn-primary text-white"
									>
										<small>View Employees</small>
									</Link>
								</div>
							</Card.Body>
						</Card>
					</Col>
				))}{" "}
			</Row>
		);
	}
};

const PagePaginationItem = ({ itemsPagePage, data }) => {
	const [currentPage, setCurrentPage] = useState(0);
	const offset = currentPage * itemsPagePage;
	const pageCount = Math.ceil(data.length / itemsPagePage);
	const currentPageData = Array.from(data).slice(
		offset,
		offset + itemsPagePage
	);

	const handlePageClick = ({ selected: selectedPage }) => {
		setCurrentPage(selectedPage);
	};

	return (
		<>
			<CompaniesList data={currentPageData} />

			<div className="d-flex justify-content-center mt-3">
				<ReactPaginate
					nextLabel="next >>"
					onPageChange={handlePageClick}
					pageRangeDisplayed={3}
					marginPagesDisplayed={2}
					pageCount={pageCount}
					previousLabel="<< previous"
					pageClassName="page-item"
					pageLinkClassName="page-link"
					previousClassName="page-item"
					previousLinkClassName="page-link"
					nextClassName="page-item"
					nextLinkClassName="page-link"
					breakLabel="..."
					breakClassName="page-item"
					breakLinkClassName="page-link"
					containerClassName="pagination"
					activeClassName="active"
					renderOnZeroPageCount={null}
				/>
			</div>
		</>
	);
};

const Companies = () => {
	const viewersRole = auth.getCurrentUserRole();

	const PageContent = () => {
		const [loading, setLoading] = useState(true);
		const [companies, setComapnies] = useState("");
		const [searchString, setSearchString] = useState("");
		const [addCompanyModal, setAddCompanyModal] = useState(false);

		useEffect(() => {
			const fetchCompanies = async () => {
				const { data } = await getCompanies();
				setComapnies(data.obj);
				setLoading(false);
			};
			fetchCompanies();
		}, []);

		return (
			<>
				<AddCompany
					show={addCompanyModal}
					onHide={() => setAddCompanyModal(false)}
				/>

				{loading ? (
					<Loading />
				) : (
					<div className="container-fluid py-3">
						{viewersRole === "4" ? (
							<div className="mb-3 d-flex justify-content-between align-items-center">
								<div className="col-6 col-md-10">
									<input
										type="text"
										className="form-control"
										placeholder="Search for company"
										value={searchString}
										onChange={(e) => setSearchString(e.target.value)}
									/>
								</div>
								<div className="">
									<button
										className="btn btn-primary text-white"
										onClick={() => setAddCompanyModal(true)}
									>
										Add company
									</button>
								</div>
							</div>
						) : (
							<div className="mb-3 d-flex justify-content-between align-items-center">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										placeholder="Search for company"
										value={searchString}
										onChange={(e) => setSearchString(e.target.value)}
									/>
								</div>
							</div>
						)}

						<div>
							<PagePaginationItem
								itemsPagePage={12}
								data={companies.filter((company) =>
									company.CompanyName.toLowerCase().includes(
										searchString.trim().toLowerCase()
									)
								)}
							/>
						</div>
					</div>
				)}
			</>
		);
	};

	return viewersRole === "3" || viewersRole === "4" ? (
		<PageContent />
	) : (
		<NotFound />
	);
};

export default Companies;
