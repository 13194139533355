import { useState } from "react";
import { useNavigate } from "react-router";
import { Spinner } from "react-bootstrap";
import {
	saveEducation,
	updateEducation,
} from "../../ApiServices/Services/UserService";

const AddEducationForm = ({ shouldReload, previousData }) => {
	let navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [qualification, setQualification] = useState(
		previousData?.Qualification ?? ""
	);
	const [instituitionName, setInstituitionName] = useState(
		previousData?.InstitutionName ?? ""
	);
	const [discipline, setDiscipline] = useState(previousData?.Discipline ?? "");
	const [learned, setLearned] = useState(previousData?.Details ?? "");
	const [startDate, setStartDate] = useState(previousData?.StartDate ?? "");
	const [endDate, setEndDate] = useState(previousData?.EndDate ?? "");

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		const info = {
			Qualification: qualification,
			InstitutionName: instituitionName,
			discipline: discipline,
			details: learned,
			StartDate: startDate,
			EndDate: endDate,
		};

		if (previousData) {
			try {
				await updateEducation({ EducationID: previousData.EID, ...info });
				if (shouldReload) {
					window.location.reload(false);
				} else {
					navigate("/uploadpicture");
				}
			} catch (Error) {
				alert("Unable to update data, try again");
				setLoading(false);
			}
		} else {
			try {
				await saveEducation(info);
				if (shouldReload) {
					window.location.reload(false);
				} else {
					navigate("/uploadpicture");
				}
			} catch (Error) {
				alert("Unable to submit data, try again");
				setLoading(false);
			}
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<div className="">
				<div className="mb-3">
					<label className="form-label fw-bold" htmlFor="qualification">
						The qualification
					</label>
					<input
						className="form-control form-control"
						id="qualification"
						type="text"
						placeholder="Type qualifications e.g Masters, Bsc, Phd"
						value={qualification}
						onChange={(e) => setQualification(e.target.value)}
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="instituite" className="form-label fw-bold">
						Instituition name
					</label>
					<input
						className="form-control form-control"
						id="instituite"
						type="text"
						placeholder="Type the institution name here..."
						value={instituitionName}
						onChange={(e) => setInstituitionName(e.target.value)}
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="field" className="form-label fw-bold">
						Field of Study
					</label>
					<input
						className="form-control form-control"
						id="field"
						type="text"
						placeholder="Type your field of study here..."
						value={discipline}
						onChange={(e) => setDiscipline(e.target.value)}
						required
					/>
				</div>
				<div className="row">
					<div className="mb-3 col-6">
						<label htmlFor="startDate" className="form-label fw-bold">
							Start date
						</label>
						<input
							className="form-control form-control"
							id="startDate"
							type="date"
							value={startDate}
							onChange={(e) => setStartDate(e.target.value)}
							required
						/>
					</div>
					<div className="mb-3 col-6">
						<label htmlFor="endDate" className="form-label fw-bold">
							End date
						</label>
						<input
							className="form-control form-control"
							id="endDate"
							type="date"
							value={endDate}
							onChange={(e) => setEndDate(e.target.value)}
							required
						/>
					</div>
				</div>
				<div className="mb-3">
					<label htmlFor="responsibilities" className="fw-bold  mb-2">
						What your learned
					</label>
					<textarea
						className="form-control"
						id="responsibilities"
						rows="5"
						placeholder="Type your responsibilites here..."
						value={learned}
						onChange={(e) => setLearned(e.target.value)}
					/>
				</div>

				<button className="btn btn-primary text-white px-5">
					{loading ? (
						<>
							<Spinner
								as="span"
								animation="grow"
								size="sm"
								role="status"
								aria-hidden="true"
							/>{" "}
							Saving
						</>
					) : (
						<>Save</>
					)}
				</button>
			</div>
		</form>
	);
};

export default AddEducationForm;
