import "./Profile.scss";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getUser } from "../../ApiServices/Services/UserService";
import { getCase } from "../../ApiServices/Services/CaseService";
import auth from "./../../ApiServices/Services/AuthService";
import ProfileWorkExperience from "./ProfileWorkExperience";
import ProfileEducation from "./ProfileEducation";
import ProfileRecommendation from "./ProfileRecommendation";
import ProfileAbout from "./ProfileAbout";
import ProfilePageHead from "./ProfilePageHead";
import Loading from "../../Components/Loading";

const Profile = () => {
	let { id } = useParams();
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState("");
	const [workExperience, setWorkExperience] = useState("");
	const [education, setEducation] = useState("");
	const [recommendation, setRecommendation] = useState("");
	const [userOwnsProfile, setUserOwnsProfile] = useState(false);
	const [userReported, setUserReported] = useState(null);

	useEffect(() => {
		if (id === auth.getCurrentUserID()) {
			setUserOwnsProfile(true);
		}

		const fetchUser = async () => {
			const { data } = await getUser(id);

			setUser(data.obj[0]);
			setWorkExperience(data.obj[0].ext.WorkExperience);
			setEducation(data.obj[0].ext.Education);
			setRecommendation(data.obj[0].ext.Recommendation);
			setLoading(false);
		};
		fetchUser();
	}, [id]);

	useEffect(() => {
		const checkIfUserIsReported = async () => {
			const { data } = await getCase(id);
			setUserReported(data.obj[0]);
		};
		checkIfUserIsReported();
	}, [id]);

	return (
		<>
			{loading ? (
				<Loading />
			) : (
				<>
					<div className="profile bg-white  m-sm-4 my-3">
						<ProfilePageHead
							data={user}
							userOwnsProfile={userOwnsProfile}
							usersCase={userReported}
						/>

						<>
							<ProfileAbout
								data={user.About}
								userOwnsProfile={userOwnsProfile}
							/>

							<hr />

							<ProfileWorkExperience
								data={workExperience}
								userOwnsProfile={userOwnsProfile}
							/>

							<hr />

							<ProfileEducation
								data={education}
								userOwnsProfile={userOwnsProfile}
							/>

							<hr />

							<ProfileRecommendation
								data={recommendation}
								user={user}
								userOwnsProfile={userOwnsProfile}
							/>
						</>
					</div>
				</>
			)}
		</>
	);
};

export default Profile;
