import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { ImCheckmark, ImCross } from "react-icons/im";
import { setEmployeeStatus } from "./../../ApiServices/Services/UserService";

const EditEmployeeStatus = (props) => {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");

	const closeModal = () => {
		setStatus("");
		setLoading(false);
		props.onHide();
	};

	const details = {
		VDID: props.vdid,
		VerificationStatusID: status,
	};
	const submitStatus = async () => {
		setLoading(true);

		try {
			const { data } = await setEmployeeStatus(details);
			if (data.Error === "") {
				window.location.reload(false);
			} else {
				alert("Error saving status");
			}
			setLoading(false);
		} catch (error) {
			alert("Error submitting data");
			setLoading(false);
		}
	};

	return (
		<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
			<Modal.Header>
				<h3 className="fw-bold">Edit {props.firstname}'s status</h3>
			</Modal.Header>

			<Modal.Body>
				<div className="d-flex justify-content-around">
					<div className="d-flex flex-column align-items-center">
						<button
							className="btn btn-success rounded-circle p-3"
							onClick={() => setStatus("1")}
						>
							<ImCheckmark className="fs-1" />
						</button>
						<span className="text-success">Approve</span>
					</div>

					<div className="d-flex flex-column align-items-center">
						<button
							className="btn btn-danger rounded-circle p-3"
							onClick={() => setStatus("0")}
						>
							<ImCross className="fs-1 fw-bold" />
						</button>
						<span className="text-danger">Disapprove</span>
					</div>
				</div>

				<div className="d-flex justify-content-between align-items-center mt-4">
					{status !== "" ? (
						<button
							className="btn btn-primary text-white px-md-5 px-3"
							onClick={submitStatus}
						>
							{loading ? (
								status === "1" ? (
									<>
										<Spinner
											as="span"
											animation="grow"
											size="sm"
											role="status"
											aria-hidden="true"
										/>{" "}
										Approving...
									</>
								) : (
									<>
										<Spinner
											as="span"
											animation="grow"
											size="sm"
											role="status"
											aria-hidden="true"
										/>{" "}
										Disapproving...
									</>
								)
							) : (
								<>Submit</>
							)}
						</button>
					) : (
						<button
							className="btn btn-primary text-white px-md-5 px-3"
							disabled
							onClick={submitStatus}
						>
							Submit
						</button>
					)}

					<button className="btn btn-danger px-md-5 px-3" onClick={closeModal}>
						Cancel
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default EditEmployeeStatus;
