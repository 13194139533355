import http, { axiosJsonToPost } from "../Utils/HttpService";
import auth from "./AuthService";

const apiEndPointSaveRecommendation = "save/recommendation";
const recommendedByID = auth.getCurrentUserID();

export function recommendUser(details) {
	return http.post(
		apiEndPointSaveRecommendation,
		axiosJsonToPost({ RecommendedBy: recommendedByID, ...details })
	);
}
