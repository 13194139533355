import "./Forms.scss";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Form, Row, Spinner, Toast, ToastContainer } from "react-bootstrap";
import {
	saveUser,
	sendEmailToken,
} from "./../../ApiServices/Services/UserService";
import { getCompanies } from "./../../ApiServices/Services/CompanyService";
import Loading from "../../Components/Loading";

const Signup = () => {
	let navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [showToastError, setShowToastError] = useState(false);
	const [showToastSuccess, setShowToastSuccess] = useState(false);
	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [email, setEmail] = useState("");
	const [company, setCompany] = useState("");
	const [jobTitle, setJobTitle] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [passwordError, setPasswordError] = useState(null);
	const [companiesList, setCompaniesList] = useState("");

	useEffect(() => {
		const getAllCases = async () => {
			const { data } = await getCompanies();
			const dataAsArray = Object.entries(data)[0][1];
			const companies = dataAsArray.map((cp) => ({
				label: cp.CompanyName,
				value: cp.CompanyID,
			}));
			setCompaniesList(companies);
		};
		getAllCases();
	}, []);

	let user = {
		Firstname: firstname,
		Lastname: lastname,
		Email: email,
		CompanyID: company.value,
		JobTitle: jobTitle,
		Password: password,
		Phone: "",
		About: "",
		RoleID: 1,
		StatusID: 1,
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		if (password !== confirmPassword) {
			setPasswordError(true);
			setLoading(false);
		} else {
			try {
				const { data } = await saveUser(user);
				if (data.Error === "") {
					setShowToastSuccess(true);
					const userID = data.obj.UserID;
					localStorage.setItem(
						"user",
						JSON.stringify({
							UserID: userID.toString(),
							RoleID: "1",
							CompanyID: company,
						})
					);
					await sendEmailToken(); //this is to send token to the user
					navigate(`../confirmemail/cra`);
				} else {
					setShowToastError(true);
					setLoading(false);
				}
			} catch (error) {
				setShowToastError(true);
				setLoading(false);
			}
		}
	};

	return companiesList === "" ? (
		<Loading />
	) : (
		<>
			<ToastContainer position="top-end">
				{showToastError && (
					<Toast
						className="d-inline-block m-1"
						bg="danger"
						onClose={() => setShowToastError(false)}
						show={showToastError}
						delay={7000}
						autohide
						animation={true}
					>
						<Toast.Header>
							<strong className="me-auto">Signup Failure</strong>
						</Toast.Header>
						<Toast.Body className="text-white">
							Unable to connect, kindly trying again.
						</Toast.Body>
					</Toast>
				)}
				{showToastSuccess && (
					<Toast
						className="d-inline-block m-1"
						bg="sucess"
						onClose={() => setShowToastError(false)}
						show={showToastSuccess}
						delay={7000}
						autohide
						animation={true}
					>
						<Toast.Header>
							<strong className="me-auto">Signup successfult</strong>
						</Toast.Header>
						<Toast.Body className="text-white">
							Dear user, your account has been created succesfully.
						</Toast.Body>
					</Toast>
				)}
			</ToastContainer>

			<div className="form-page d-flex justify-content-center align-items-center p-2">
				<div className="form-container container bg-white pt-3 pb-1 px-sm-4 px-3">
					<div className="progresss d-flex justify-content-center">
						<span className="active"></span>
						<span></span>
						<span></span>
						<span></span>
					</div>
					<h3 className="text-center mb-2 fw-bold">Create an account</h3>
					{passwordError && (
						<p className="text-danger fw-bold mt-2">
							* Password and confirm password do not match
						</p>
					)}
					<Form onSubmit={handleSubmit}>
						<Row className="mb-3">
							<Form.Group
								className="mb-3 col-md-6"
								controlId="formBasicFirstname"
							>
								<Form.Label className="fw-bold fs-6">FIRSTNAME</Form.Label>
								<Form.Control
									type="text"
									placeholder="Your firstname"
									value={firstname}
									onChange={(e) => setFirstname(e.target.value)}
									autoComplete="off"
								/>
							</Form.Group>

							<Form.Group
								className="mb-3 col-md-6"
								controlId="formBasicLastname"
							>
								<Form.Label className="fw-bold fs-6">LASTNAME</Form.Label>
								<Form.Control
									type="text"
									placeholder="Your firstname"
									onChange={(e) => setLastname(e.target.value)}
									value={lastname}
									autoComplete="off"
								/>
							</Form.Group>
						</Row>

						<Form.Group className="mb-3" controlId="formBasicEmail">
							<Form.Label className="fw-bold fs-6">EMAIL</Form.Label>
							<Form.Control
								type="email"
								placeholder="Your email"
								onChange={(e) => setEmail(e.target.value)}
								value={email}
								autoComplete="off"
							/>
						</Form.Group>

						<Row className="mb-3">
							<Form.Group className="mb-3 col-md-6">
								<Form.Label className="fw-bold fs-6">COMPANY</Form.Label>
								<Select
									options={companiesList}
									placeholder="Select company"
									value={company}
									onChange={(e) => setCompany(e)}
								/>
							</Form.Group>

							<Form.Group
								className="mb-3 col-md-6"
								controlId="formBasicJobTitle"
							>
								<Form.Label className="fw-bold fs-6">JOB TITLE</Form.Label>
								<Form.Control
									type="text"
									placeholder="Your job title"
									onChange={(e) => setJobTitle(e.target.value)}
									value={jobTitle}
									autoComplete="off"
								/>
							</Form.Group>
						</Row>

						<Row className="mb-3">
							<Form.Group
								className="mb-3 col-md-6"
								controlId="formBasicPassword"
							>
								<Form.Label className="fw-bold fs-6">PASSWORD</Form.Label>
								<Form.Control
									type="password"
									placeholder="Your Password"
									onChange={(e) => setPassword(e.target.value)}
									value={password}
								/>
							</Form.Group>
							<Form.Group
								className="mb-3 pt-1  col-md-6"
								controlId="formBasicConfirmPassword"
							>
								<Form.Label className="fw-bold fs-6">
									CONFIRM PASSWORD
								</Form.Label>
								<Form.Control
									type="password"
									placeholder="Confirm Password"
									value={confirmPassword}
									onChange={(e) => setConfirmPassword(e.target.value)}
								/>
							</Form.Group>
						</Row>

						<div className="d-grid gap-2 mt-4">
							<button
								className="btn btn-primary text-white py-2 fw-bold"
								type="submit"
							>
								{loading ? (
									<>
										<Spinner
											as="span"
											animation="grow"
											size="sm"
											role="status"
											aria-hidden="true"
										/>{" "}
										Creating account
									</>
								) : (
									<>Sign up</>
								)}
							</button>
						</div>

						<Link to="/login" className="text-decoration-none">
							<p className="mt-2 text-center text-primary ">
								Already have an account?
							</p>
						</Link>
					</Form>
				</div>
			</div>
		</>
	);
};

export default Signup;
