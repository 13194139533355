import { useState } from "react";
import "./SearchPeople.scss";

const SearchPeople = (props) => {
	const [input, setInput] = useState("");

	// const findUser = (e) => {
	// 	e.preventDefault();
	// 	props.setLoading(true);
	// 	props.func(input.trim());
	// };

	const handleSearch = (e) => {
		const name = e.target.value;
		setInput(name);
		props.setLoading(true);
		props.func(input);
	};

	return (
		<div className="searchPeople px-sm-5 px-3 pt-sm-5 pt-3 pt-2 pt-xs-2 pb-4 bg-white hoverable">
			<h3 className="fw-bold text-center mb-4">Search people here</h3>
			{/* <form onSubmit={findUser}> */}
			<div className="form d-flex align-items-center justify-content-center ">
				<input
					type="text"
					placeholder="Search here"
					className="me-3 form-control"
					value={input}
					onInput={(e) => setInput(e.target.value)}
					onChange={handleSearch}
					required
					minLength="3"
					autoComplete="off"
				/>
				{/* <button className="btn btn-primary text-white">Search </button>*/}
			</div>
			{/* </form> */}
		</div>
	);
};

export default SearchPeople;
