import { Modal, Spinner } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useState } from "react";
import { updateUser } from "../../ApiServices/Services/UserService";

const EditAbout = (props) => {
	const [editedAbout, setEditedAbout] = useState(props.about);
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			await updateUser({ About: editedAbout });
			window.location.reload(false);
		} catch (Error) {
			alert("Unable to submit data, try again");
			setLoading(false);
		}
	};

	return (
		<Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter">
			<div className="bg-white rounded p-sm-3 p-2">
				<div className="mb-3" style={{ position: "relative" }}>
					<h3 className="fw-bold">About you</h3>
					<button
						onClick={props.onHide}
						className="btn"
						style={{
							position: "absolute",
							top: "0",
							right: "0",
							fontSize: "20px",
							cursor: "pointer",
						}}
					>
						<AiOutlineClose />{" "}
					</button>
				</div>
				<hr />
				<form onSubmit={(e) => handleSubmit(e)}>
					<div className="">
						<label htmlFor="bio" className="fw-bold  mb-2">
							Your bio
						</label>
						<textarea
							className="form-control mb-4"
							id="bio"
							rows="5"
							placeholder="Write your bio here...
                            "
							value={editedAbout}
							onChange={(e) => setEditedAbout(e.target.value)}
						/>
						<button className="btn btn-primary text-white px-sm-5">
							{loading ? (
								<>
									<Spinner
										as="span"
										animation="grow"
										size="sm"
										role="status"
										aria-hidden="true"
									/>{" "}
									Saving
								</>
							) : (
								<>Save</>
							)}
						</button>
					</div>
				</form>

				<div className="d-flex justify-content-end mt-n3">
					<button
						className="btn btn-danger px-sm-5 mt-n4"
						onClick={props.onHide}
					>
						Cancel
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default EditAbout;
