import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { reportedUserComment } from "../../ApiServices/Services/CaseService";

const UserReportVersion = (props) => {
	const [loading, setLoading] = useState(false);
	const [comment, setComment] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			await reportedUserComment({
				ReportID: props.report.ReportID,
				Details: comment,
				UserID: props.report.UserID,
			});
			window.location.reload(false);
		} catch (error) {
			alert("Error submitting data, try again");
			setLoading(false);
		}
	};

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<h3 className="fw-bold pe-4">
					You were reported by{" "}
					<span className="text-danger ">{props.report.CompanyName}</span>
				</h3>
			</Modal.Header>
			<Modal.Body>
				<div className="bg-white rounded">
					<div>
						<p>
							<span className="fw-bold">What was the problem</span>
							<span className="text-secondary d-block">
								{props.report.Details}
							</span>
						</p>
					</div>

					<p className="fw-bold">
						<span>Proposed disciplinary action</span>{" "}
						<span className="text-danger d-block d-lg-inline">
							{props.report.ActionDetails}
						</span>
					</p>

					{props.report.ext.UserStories.length > 0 ? (
						<div className="mb-5">
							<span className="fw-bold">You said</span> <br />
							<span className="">
								{props.report.ext.UserStories[0].Details}
							</span>
						</div>
					) : (
						<form onSubmit={handleSubmit}>
							<div className="">
								<label htmlFor="forProblem" className="fw-bold  mb-2">
									Present your version
								</label>
								<textarea
									className=" form-control mb-4"
									id="forProblem"
									rows="6"
									value={comment}
									onChange={(e) => setComment(e.target.value)}
									placeholder="Type your message here...
                            "
								/>
							</div>

							<button className="btn btn-primary text-white px-5">
								{loading ? (
									<>
										<Spinner
											as="span"
											animation="grow"
											size="sm"
											role="status"
											aria-hidden="true"
										/>{" "}
										Submitting
									</>
								) : (
									<>Submit</>
								)}
							</button>
						</form>
					)}

					<div className="d-flex justify-content-end mt-n3">
						<button
							className="btn btn-danger px-5 mt-n4"
							onClick={props.onHide}
						>
							Cancel
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default UserReportVersion;
